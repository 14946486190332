import {
  DataTable,
  DataTableColumn,
  DateTimeFormatter,
  SortDirection,
  SortFilters,
  tableSort,
  urlReplace,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../../constants/RouteConstants";
import { ReportData } from "../../../types/Reports";

const Columns: DataTableColumn<ReportData>[] = [
  {
    name: "File Name",
    key: "fileName",
    sortable: true,
  },
  {
    name: "Email",
    key: "email",
    sortable: true,
  },
  {
    name: "Org",
    key: "org",
    width: 125,
    sortable: true,
  },
  {
    name: "Count",
    key: "viewCount",
    width: 75,
    sortable: true,
  },
  {
    name: "Date",
    key: "viewDate",
    width: 150,
    sortable: true,
    readonlyFormatter: DateTimeFormatter,
  },
];

type AdminReportingResultsProps<T extends ReportData> = {
  data: T[];
  defaultSortKey: keyof T;
};

const AdminReportingResults = <T extends ReportData>(props: AdminReportingResultsProps<T>) => {
  const { data, defaultSortKey } = props;
  const navigate = useNavigate();
  const [sortFilters, setSortFilters] = useState<SortFilters<T>>({
    direction: "ASC",
    key: defaultSortKey,
  });
  const [sortedData, setSortedData] = useState<T[]>([]);

  useEffect(() => {
    setSortedData(tableSort(data, sortFilters));
  }, [data, sortFilters]);

  return (
    <DataTable
      data={sortedData}
      columns={Columns}
      rowClick={{
        onClick: (_index: number, data: ReportData) => {
          navigate(urlReplace(RouteConstants.VIDEO, { videoId: data.fileId }));
        },
      }}
      sortFunction={(key: keyof T, direction: SortDirection) => {
        setSortFilters({ direction: direction, key: key });
      }}
      sortColumn={sortFilters.key.toString()}
      sortDirection={sortFilters["direction"]}
    />
  );
};

export default AdminReportingResults;
