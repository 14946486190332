import { sort, SortFilters } from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SearchResultGrouped } from "../../../types/Search";
import PlayerSearchResult from "./PlayerSearchResult";

const Holder = styled.div`
  position: relative;
`;

const NoResultsFound = styled.div`
  text-align: center;
  width: 100%;
  font-weight: 500;
`;

type PlayerSearchResultsProps = {
  data: SearchResultGrouped[];
  hasSearched?: boolean;
};

const PlayerSearchResults: React.FC<PlayerSearchResultsProps> = ({ data, hasSearched = false }) => {
  const [sorted, setSorted] = useState<SearchResultGrouped[]>([]);

  const [sortFilters] = useState<SortFilters<SearchResultGrouped>>({
    direction: "ASC",
    key: "playerName",
  });

  useEffect(() => {
    setSorted(sort(data || [], sortFilters.key, sortFilters.direction === "ASC" ? 1 : -1));
  }, [sortFilters, data]);

  return (
    <Holder>
      {!!sorted?.length ? (
        sorted.map((item) => <PlayerSearchResult key={item.playerName} data={item} />)
      ) : (
        <div>{hasSearched && <NoResultsFound>No search results found</NoResultsFound>}</div>
      )}
    </Holder>
  );
};

export default PlayerSearchResults;
