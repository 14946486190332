import "best-common-react/lib/styles/bcr.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "video-react/dist/video-react.css";
import App from "./App";
import Contexts from "./contexts";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Contexts>
      <App />
    </Contexts>
  </BrowserRouter>,
);
