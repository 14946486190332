import { OktaAuth } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { AlertProvider, LoadingProvider } from "best-common-react";
import React from "react";
import { AuthProvider, oktaAuth } from "./AuthContext";
import { MetadataProvider } from "./MetadataContext";

const Contexts: React.FC<React.PropsWithChildren> = ({ children }) => {
  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    window.location.replace(originalUri);
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <AlertProvider>
        <LoadingProvider>
          <AuthProvider>
            <MetadataProvider>{children}</MetadataProvider>
          </AuthProvider>
        </LoadingProvider>
      </AlertProvider>
    </Security>
  );
};

export default Contexts;
