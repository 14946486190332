import { Button, Card, DataTable, FormColumn, Input, StickyFooterButtons, useLoading } from "best-common-react";
import React, { useEffect, useState } from "react";
import Protected from "../components/protected/Protected";
import { useMetadata } from "../contexts/MetadataContext";
import { addTag } from "../httpClients/ClipsApi";
import { Tags } from "../types/Tags";

const Columns = [
  {
    name: "Tag",
    key: "tag",
  },
  {
    name: "Org",
    key: "org",
  },
];

const TagsContainer: React.FC = () => {
  const { setLoading } = useLoading();
  const { tags, refetchTags } = useMetadata();
  const [tag, setTag] = useState<string>("");
  const [tagSearch, setTagSearch] = useState<string>("");
  const [displayTagList, setDisplayTagList] = useState<Tags[]>([]);
  const [validToSubmit, setValidToSubmit] = useState<boolean>(false);

  const addTagCall = async () => {
    try {
      setLoading(true);
      await addTag(tag);
      setTag("");
      setTagSearch("");
      await refetchTags();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const tagsToDisplay: Tags[] = tags.filter((t: Tags) => {
      if (tagSearch.length) {
        return !!t.tag && t.tag.includes(tagSearch);
      } else {
        return true;
      }
    });
    setDisplayTagList(tagsToDisplay);
  }, [tagSearch, tags]);

  useEffect(() => {
    setValidToSubmit(!!tag?.length);
  }, [tag]);

  useEffect(() => {
    void refetchTags();
  }, []);

  return (
    <div>
      <Card>
        <Card.Header>Tags</Card.Header>
        <Card.Body>
          <div>
            <form>
              <div className="row">
                <FormColumn width={2}>
                  <Input
                    id="newTag"
                    label="New Tag"
                    value={tag}
                    onChange={(value) => setTag(value)}
                    placeholder="New Tag"
                  />
                </FormColumn>
                <FormColumn width={2}>
                  <Input
                    id="tagSearch"
                    label="Tag Search"
                    value={tagSearch}
                    onChange={(value) => setTagSearch(value)}
                    placeholder="Tag Search"
                  />
                </FormColumn>
              </div>
            </form>
          </div>
          <div className="mt-2">
            <DataTable data={displayTagList} columns={Columns} />
          </div>
        </Card.Body>
        <Card.Footer>
          <StickyFooterButtons>
            <Button variant="primary" disabled={!validToSubmit} onClick={addTagCall}>
              Add Tag
            </Button>
          </StickyFooterButtons>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default Protected(TagsContainer);
