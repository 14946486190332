import { Tab, TabSwitcher } from "best-common-react";
import React, { useEffect } from "react";
import { useAdminReporting } from "../../../contexts/ admin/AdminReportingContext";
import AdminUserViews from "./AdminUserViews";
import AdminVideoDownloads from "./AdminVideoDownloads";

const AdminBody = () => {
  const { setActiveTab } = useAdminReporting();
  const Tabs: Tab[] = [
    {
      name: "Views",
      component: AdminUserViews,
    },
    {
      name: "Downloads",
      component: AdminVideoDownloads,
    },
  ];

  const setActiveTabByIndex = (index: number) => {
    setActiveTab(Tabs[index]);
    return true;
  };

  useEffect(() => {
    setActiveTab(Tabs[0]);
  }, []);

  return <TabSwitcher tabs={Tabs} setActiveTabIndex={setActiveTabByIndex} />;
};

export default AdminBody;
