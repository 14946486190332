import { Button, Checkbox, Typography } from "best-common-react";
import React from "react";
import styled from "styled-components";
import { useAuth } from "../../../contexts/AuthContext";
import { useUploads } from "../../../contexts/UploadContext";
import FileUploadAndPreview from "../../file/FileUploadAndPreview";
import MetadataForm from "../../metadata/MetadataForm";

const UploadContainer = styled.div.attrs({
  className: "me-2",
})`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StraightToFinalContainer = styled.div`
  width: 10rem;
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  margin-left: 2rem;
`;

const SingleFileUpload = () => {
  const { isAdmin } = useAuth();
  const { metadata, setMetadata, upload, canSave, straightToFinal, setStraightToFinal } = useUploads();
  return (
    <div className="p-2">
      <Typography variant="h4">Single File Upload</Typography>
      <UploadContainer>
        <FileUploadAndPreview />
        <Button variant="primary" className="ms-2 h-100 mt-2" onClick={upload} disabled={!canSave}>
          Upload
        </Button>
        {!!isAdmin && (
          <StraightToFinalContainer>
            <Checkbox
              label="Straight to final"
              id="straightToFinal"
              className="me-2"
              checked={!!straightToFinal}
              onChange={() => {
                setStraightToFinal(!straightToFinal);
              }}
            />
          </StraightToFinalContainer>
        )}
      </UploadContainer>
      <MetadataForm metadata={metadata} onChange={setMetadata} />
    </div>
  );
};

export default SingleFileUpload;
