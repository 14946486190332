import { Accordion, DataTable, DataTableColumn, DATE_FORMATTER_STR_WITH_TIME, formatDate } from "best-common-react";
import React from "react";
import { useUploads } from "../../../contexts/UploadContext";
import { UploadBatch, UploadBatchErrors } from "../../../types/Upload";

const Columns: DataTableColumn<UploadBatchErrors>[] = [
  {
    name: "File name",
    key: "fileName",
    width: 500,
  },
  {
    name: "Error",
    key: "errorDesc",
    width: 800,
  },
];

type UploadStatusProps = {
  status: UploadBatch;
};

const UploadStatus: React.FC<UploadStatusProps> = ({ status }) => (
  <div className="my-2">
    <Accordion accordionKey={status.id}>
      <Accordion.Header>
        <div className="d-flex flex-column">
          <div>
            <b>Upload:</b> {formatDate(new Date(status.startTime), DATE_FORMATTER_STR_WITH_TIME)}
          </div>
          <div>
            <b>Files:</b>{" "}
            {status.finished + status.errors === status.length
              ? status.length
              : `${status.finished} out of ${status.length}`}
          </div>
          <div>
            <b>Errors:</b> {status.errors}
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <DataTable columns={Columns} data={status.uploadBatchErrors} />
      </Accordion.Body>
    </Accordion>
  </div>
);

const UploadStatuses: React.FC = () => {
  const { uploadStatuses } = useUploads();
  return (
    <div className="mt-2">
      {uploadStatuses.map((status: UploadBatch) => (
        <UploadStatus key={status.id} status={status} />
      ))}
    </div>
  );
};

export default UploadStatuses;
