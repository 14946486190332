import { Tab, TabSwitcher } from "best-common-react";
import React, { useEffect } from "react";
import { useSearch } from "../../contexts/SearchContext";
import AdvancedSearchBody from "./advancedSearch/AdvancedSearchBody";
import PlayerSearchBody from "./playerSearch/PlayerSearchBody";

const SearchBody = () => {
  const { setActiveTab } = useSearch();
  const Tabs: Tab[] = [
    {
      name: "Player",
      component: PlayerSearchBody,
    },
    {
      name: "Advanced",
      component: AdvancedSearchBody,
    },
  ];

  const setActiveTabByIndex = (index) => {
    setActiveTab(Tabs[index]);
  };

  useEffect(() => {
    setActiveTab(Tabs[0]);
  }, []);

  return (
    <div>
      <TabSwitcher tabs={Tabs} setActiveTabIndex={setActiveTabByIndex} />
    </div>
  );
};

export default SearchBody;
