import { NavigationConfigType, NavigationV2 } from "best-common-react";
import React from "react";
import { RouteConstants } from "../../constants/RouteConstants";
import { useAuth } from "../../contexts/AuthContext";
import { useMetadata } from "../../contexts/MetadataContext";

const { ENV } = window.MLBBest.envVariables || {};

const ClipsNavigation = () => {
  const { refetchData } = useMetadata();
  const { teamLogo, loggedIn, isAdmin, logout, getUserName, isSubscribed, toggleSubscribed } = useAuth();
  const config: NavigationConfigType = {
    env: ENV,
    displayEnvIndicator: false,
    loggedIn: loggedIn,
    username: getUserName(),
    startLogo: {
      logo: teamLogo,
      link: RouteConstants.BASE,
      visible: true,
    },
    title: "",
    builds: [],
    tabletSupport: false,
    usernameDropdownLinks: [
      {
        name: "Refresh Metadata",
        onClick: refetchData,
      },
      {
        name: !isSubscribed ? "Subscribe to Daily Email" : "Unsubscribe to Daily Email",
        onClick: toggleSubscribed,
      },
      {
        name: "Sign Out",
        onClick: logout,
      },
    ],
    navigationLinks: [
      {
        name: "Search",
        to: RouteConstants.SEARCH,
      },
      {
        name: "Uploads",
        to: RouteConstants.UPLOADS,
      },
      {
        name: "Folders",
        to: RouteConstants.FOLDERS,
      },
      {
        name: "Tags",
        to: RouteConstants.TAGS,
      },
      {
        name: "Admin",
        visible: isAdmin,
        subLinks: [
          {
            name: "Reporting",
            to: RouteConstants.ADMIN_REPORTING,
            visible: isAdmin,
          },
          {
            name: "Metadata",
            to: RouteConstants.ADMIN_METADATA,
            visible: isAdmin,
          },
          // {
          //   name: "User",
          //   to: RouteConstants.ADMIN_USER,
          //   visible: false && isAdmin
          // },
          // {
          //   name: "Application",
          //   to: RouteConstants.ADMIN_APPLICATION,
          //   visible: false && isAdmin
          // }
        ],
      },
    ],
  };

  return <NavigationV2 config={config} />;
};

export default ClipsNavigation;
