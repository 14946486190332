import { Button, FileAttachment, Modal } from "best-common-react";
import React, { useState } from "react";
import styled from "styled-components";
import { useUploads } from "../../contexts/UploadContext";
import Video from "../video/videoPlayer/Video";

const Container = styled.div`
  width: 100%;
`;

const FileUploadAndPreview = () => {
  const { setFileData, file, fileContent } = useUploads();
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <Container>
      <Modal show={modalOpen} size="lg">
        <Modal.Header
          close={() => {
            setModalOpen(false);
          }}
        >
          File Preview
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <Video url={fileContent} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="default"
            onClick={(event) => {
              event.stopPropagation();
              setModalOpen(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <FileAttachment
        id="browseFiles"
        label="File to Upload"
        onChange={(files: File[]) => {
          setFileData(files);
        }}
        fileTypes={["video/mp4", "video/mpeg", "video/quicktime"]}
        files={!!file ? [file] : []}
        maxFiles={1}
      />
    </Container>
  );
};

export default FileUploadAndPreview;
