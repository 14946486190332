import React from "react";
import ReactPaginate from "react-paginate";
import { useSearch } from "../../contexts/SearchContext";

const SearchPagination = () => {
  const { pagination, setPagination } = useSearch();

  const onPageChange = (selectedItem) => {
    setPagination({ ...pagination, pageNumber: selectedItem.selected });
  };

  return (
    <div className="mt-5 d-flex justify-content-end">
      {pagination.totalCount > 0 && (
        <ReactPaginate
          pageCount={Math.ceil(pagination.totalCount / pagination.pageSize)}
          onPageChange={onPageChange}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          initialPage={pagination.pageNumber}
          containerClassName="pagination"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          activeClassName="active"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          nextLabel="Next"
          previousLabel="Previous"
        />
      )}
    </div>
  );
};

export default SearchPagination;
