import { Tab, TabSwitcher } from "best-common-react";
import React, { useEffect } from "react";
import { UploadConstants } from "../../constants/UploadConstants";
import { useUploads } from "../../contexts/UploadContext";
import MultiFileUpload from "./multi/MultiFileUpload";
import SingleFileUpload from "./single/SingleFileUpload";

const Uploads = () => {
  const { setActiveTab } = useUploads();
  const Tabs: Tab[] = [
    {
      name: UploadConstants.Tabs.Single,
      component: SingleFileUpload,
    },
    {
      name: UploadConstants.Tabs.Multi,
      component: MultiFileUpload,
    },
  ];

  const setActiveTabByIndex = (index: number) => {
    setActiveTab(Tabs[index]);
  };

  useEffect(() => {
    setActiveTab(Tabs[0]);
  }, []);

  return <TabSwitcher tabs={Tabs} setActiveTabIndex={setActiveTabByIndex} />;
};

export default Uploads;
