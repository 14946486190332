import React from "react";
import { useSearch } from "../../../contexts/SearchContext";
import PlayerSearchResults from "../results/PlayerSearchResults";
import SearchPagination from "../SearchPagination";
import AdvancedSearchForm from "./AdvancedSearchForm";

const AdvancedSearchBody = () => {
  const { searchResults, hasSearched } = useSearch();
  return (
    <div className="p-2">
      <AdvancedSearchForm />
      <PlayerSearchResults data={searchResults} hasSearched={hasSearched} />
      <SearchPagination />
    </div>
  );
};

export default AdvancedSearchBody;
