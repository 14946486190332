import { Button, StickyFooterButtons } from "best-common-react";
import React from "react";
import { useAdminReporting } from "../../../contexts/ admin/AdminReportingContext";

const AdminReportingFooter = () => {
  const { isValidSearch, search } = useAdminReporting();
  return (
    <StickyFooterButtons>
      <Button variant="primary" onClick={search} disabled={!isValidSearch}>
        Search
      </Button>
    </StickyFooterButtons>
  );
};

export default AdminReportingFooter;
