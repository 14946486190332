import { Button, Checkbox, FileAttachment, Typography } from "best-common-react";
import React from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";
import { MetadataConstants } from "../../../constants/MetadataConstants";
import { useAuth } from "../../../contexts/AuthContext";
import { useUploads } from "../../../contexts/UploadContext";
import UploadStatuses from "./UploadStatuses";

const UploadContainer = styled.div.attrs({
  className: "me-2",
})`
  display: flex;
  align-items: start;
  width: 100%;
`;

const StraightToFinalContainer = styled.div`
  width: 10rem;
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  margin-left: 2rem;
`;

const MultiFileUpload = () => {
  const { isAdmin } = useAuth();
  const { csvFile, setCsvData, upload, canSave, straightToFinal, setStraightToFinal } = useUploads();

  return (
    <div className="p-2">
      <Typography variant="h4">Multi File Upload</Typography>
      <div className="mb-3">
        <CSVLink className="me-3" filename="DomesticTemplate.csv" data={MetadataConstants.DOMESTIC} target="_blank">
          Domestic Template
        </CSVLink>
        <CSVLink filename="InternationalTemplate.csv" data={MetadataConstants.INTERNATIONAL} target="_blank">
          International Template
        </CSVLink>
      </div>
      <UploadContainer>
        <div style={{ width: "100%" }}>
          <FileAttachment
            id="multi-file-upload"
            maxFiles={1}
            onChange={setCsvData}
            fileTypes={["text/csv", "application/vnd.ms-excel"]}
            files={!!csvFile ? [csvFile] : []}
          />
        </div>
        <Button variant="primary" className="ms-2" onClick={upload} disabled={!canSave}>
          Upload
        </Button>
        {!!isAdmin && (
          <StraightToFinalContainer>
            <Checkbox
              label="Straight to final"
              id="straightToFinal"
              className="me-2"
              checked={!!straightToFinal}
              onChange={() => {
                setStraightToFinal(!straightToFinal);
              }}
            />
          </StraightToFinalContainer>
        )}
      </UploadContainer>
      <div className="mb-3">
        <UploadStatuses />
      </div>
    </div>
  );
};

export default MultiFileUpload;
