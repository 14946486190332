import { MetadataConstants } from "../constants/MetadataConstants";
import { ClipsMetadataDTO } from "../types/Video";

const DATE_FIELDS = ["birthDate", "filmDate"];

export const isDateField = (key: string): boolean => {
  return DATE_FIELDS.indexOf(key) >= 0;
};

export const getNewKey = (searchKey: string): string => {
  const keyMappings = MetadataConstants.keyMappings;
  for (const key in keyMappings) {
    if (keyMappings[key] && keyMappings[key].toUpperCase() === searchKey.toUpperCase()) {
      return key;
    }
  }
  return "";
};

export const convertMetadata = (metadata: ClipsMetadataDTO[]): ClipsMetadataDTO[] => {
  return metadata.map((m) => {
    const meta = {};
    const keys = Object.keys(m);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const newKey = getNewKey(key);
      let finalValue = m[key];
      if (finalValue && finalValue !== "") {
        if (isDateField(newKey)) {
          finalValue = new Date(finalValue + " 8:00:00");
        }
        if (newKey === "tags") {
          if (!Array.isArray(finalValue)) {
            if (finalValue.includes(",")) {
              finalValue = finalValue.split(",").map((el) => el.trim());
            } else {
              finalValue = [finalValue];
            }
          }
        }
        meta[newKey] = finalValue;
      }
    }
    return meta;
  });
};
