import { Card } from "best-common-react";
import React from "react";
import Protected from "../components/protected/Protected";
import Uploads from "../components/uploads/Uploads";
import UploadsFooter from "../components/uploads/UploadsFooter";
import { UploadProvider } from "../contexts/UploadContext";

const UploadsContainer: React.FC = () => (
  <UploadProvider>
    <Card>
      <Card.Header>Uploads</Card.Header>
      <Card.Body>
        <Uploads />
      </Card.Body>
      <Card.Footer>
        <UploadsFooter />
      </Card.Footer>
    </Card>
  </UploadProvider>
);

export default Protected(UploadsContainer);
