import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default (WrappedComponent) => {
  const AdminProtected = ({ ...rest }) => {
    const { isAdmin } = useAuth();
    if (isAdmin) {
      return <WrappedComponent {...rest} />;
    } else {
      return <Navigate to="/" replace={true} />;
    }
  };

  return AdminProtected;
};
