import { Card, useLoading } from "best-common-react";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ProfileSearchFilters from "../components/profile/ProfileSearchFilters";
import ProfileSearchResults from "../components/profile/ProfileSearchResults";
import { useMetadata } from "../contexts/MetadataContext";
import { search } from "../httpClients/ClipsApi";
import { BoxFileDTO } from "../types/Video";

type ProfileParams = {
  playerId?: string;
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ProfileContainer: React.FC = () => {
  const { setLoading } = useLoading();
  const query = useQuery();
  const { tags } = useMetadata();

  // Search Parameters
  const { playerId } = useParams<ProfileParams>();
  const [source, setSource] = useState<string>();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  useEffect(() => {
    const sourceQueryParam = query.get("source");
    if (!!sourceQueryParam) {
      setSource(sourceQueryParam);
    }

    const tagsQueryParam = query.get("tags")?.toLowerCase().split(",");
    if (tagsQueryParam && tagsQueryParam.length) {
      console.log(tagsQueryParam);
      setSelectedTags(tags.filter(({ tag }) => tagsQueryParam.includes(tag.toLowerCase())).map(({ tag }) => tag));
    }
  }, [tags]);

  const [results, setResults] = useState<BoxFileDTO[]>([]);

  const fetch = async () => {
    try {
      setLoading(true);
      const { results } = await search({
        ebis: playerId,
        source: source,
        tags: !!selectedTags.length ? selectedTags : undefined,
      });
      setResults(results);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (playerId) {
      fetch();
    }
  }, [playerId, source, selectedTags]);

  return (
    <Card>
      <Card.Header>Player Id: {playerId}</Card.Header>
      <Card.Body>
        <ProfileSearchFilters
          source={source}
          setSource={setSource}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
        {results.map((file) => (
          <ProfileSearchResults key={file.fileId} files={results} />
        ))}
      </Card.Body>
    </Card>
  );
};

export default ProfileContainer;
