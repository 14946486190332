import { removeNullFields, Tab, useLoading } from "best-common-react";
import * as Papa from "papaparse";
import React, { useContext, useEffect, useState } from "react";
import { UploadConstants } from "../constants/UploadConstants";
import { multiFileUpload, singleFileUpload, uploadStatus } from "../httpClients/ClipsApi";
import { UploadBatch } from "../types/Upload";
import { ClipsMetadataDTO } from "../types/Video";
import { convertMetadata } from "../utils/MetadataUtils";
import { useAuth } from "./AuthContext";

type UploadContextType = {
  uploadStatuses: UploadBatch[];
  metadata: ClipsMetadataDTO;
  file: File;
  fileContent: string;
  canSave: boolean;
  straightToFinal: boolean;
  csvFile: File;
  setCsvData: (value: File[]) => void;
  setMetadata: (value: ClipsMetadataDTO) => void;
  setFileData: (value: File[]) => void;
  setStraightToFinal: (value: boolean) => void;
  upload: () => Promise<any>;
  setActiveTab: (value: Tab) => void;
};

const UploadContext = React.createContext<UploadContextType | undefined>(undefined);

const UploadProvider = ({ children }) => {
  const { userInfo } = useAuth();
  const { setLoading } = useLoading();
  const defaultMetadata: ClipsMetadataDTO = {
    firstName: "",
    lastName: "",
    uploadDate: new Date(),
    source: userInfo?.org?.name,
    org: userInfo?.org?.name,
  };
  const [activeTab, setActiveTab] = useState<Tab | undefined>(undefined);
  const [metadata, setMetadata] = useState<ClipsMetadataDTO>(defaultMetadata);
  const [csvFile, setCsvFile] = useState<File | undefined>(undefined);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [fileContent, setFileContent] = useState<string>("");
  const [csv, setCsv] = useState<ClipsMetadataDTO[]>([]);
  const [uploadStatuses, setUploadStatuses] = useState<UploadBatch[]>([]);
  const [straightToFinal, setStraightToFinal] = useState<boolean>(false);
  const [canSave, setCanSave] = useState<boolean>(false);

  const resetData = () => {
    setCsvFile(undefined);
    setFile(undefined);
    setCsv([]);
    setStraightToFinal(false);
    setFileContent("");
    setMetadata(defaultMetadata);
  };

  const singleFileUploadCall = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append(
        "params",
        JSON.stringify({
          fileName: file?.name,
          fileSize: file?.size,
          metadata: metadata,
        }),
      );
      formData.append("file", file);
      await singleFileUpload(formData, straightToFinal);
      resetData();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const multiFileUploadCall = async () => {
    try {
      setLoading(true);
      const uploadData = {
        metadata: csv.map((d) => ({
          ...d,
          org: userInfo?.org?.name,
          tags: !!d.tags && !Array.isArray(d.tags) ? (d.tags as string).split(",") : d.tags,
        })),
        straightToFinal: straightToFinal,
      };
      await multiFileUpload(uploadData);
      resetData();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const upload = async () => {
    if (activeTab) {
      if (activeTab.name === UploadConstants.Tabs.Single) {
        return singleFileUploadCall();
      } else if (activeTab.name === UploadConstants.Tabs.Multi) {
        return multiFileUploadCall();
      }
    }
  };

  const setFileData = (files) => {
    if (files.length) {
      const video = files[0];
      setFile(video);
      //todo: explore reading video file to use on a video preview
      // const reader = new FileReader();
      // reader.onload = () => {
      //   console.debug("File read: " + reader.result);
      //   setFileContent(reader.result);
      // };
      // reader.readAsText(video);
    } else {
      setFile(undefined);
    }
  };

  const fetchUploadStatus = async () => {
    const result: UploadBatch[] = await uploadStatus();
    setUploadStatuses(result);
  };

  const setCsvData = (files: File[]) => {
    if (files.length) {
      const csvFileData = files[0];
      setCsvFile(csvFileData);
      const reader = new FileReader();
      reader.onload = () => {
        console.debug("File read: " + reader.result);
        setFileContent(reader.result as string);
        const tempCsvData: ClipsMetadataDTO[] = Papa.parse(reader.result, { header: true }).data;
        const csvData: ClipsMetadataDTO[] = !!tempCsvData ? tempCsvData.map((d) => removeNullFields(d)) : [];
        console.debug("CSV Data: " + csvData);
        setCsv(convertMetadata(csvData));
      };
      reader.readAsText(csvFileData);
    } else {
      setCsvFile(undefined);
      setCsv([]);
    }
  };

  useEffect(() => {
    if (activeTab?.name === UploadConstants.Tabs.Multi) {
      const interval = setInterval(() => {
        void fetchUploadStatus();
      }, 5000);
      return () => clearInterval(interval);
    } else {
      return () => {
        console.debug("ignore");
      };
    }
  }, [activeTab]);

  useEffect(() => {
    let canUserSave = false;
    if (!!activeTab) {
      if (activeTab.name === UploadConstants.Tabs.Single) {
        canUserSave =
          !!metadata.firstName &&
          !!metadata.lastName &&
          !!metadata.playerStatus &&
          !!file &&
          !!Object.keys(file).length;
      } else if (activeTab.name === UploadConstants.Tabs.Multi) {
        canUserSave = !!csv.length;
      }
    }
    setCanSave(canUserSave);
  }, [activeTab, metadata, file, csv]);

  return (
    <UploadContext.Provider
      value={{
        uploadStatuses,
        metadata,
        file,
        fileContent,
        canSave,
        straightToFinal,
        csvFile,
        setCsvData,
        setMetadata,
        setFileData,
        setStraightToFinal,
        upload,
        setActiveTab,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};

const useUploads = () => {
  const context: UploadContextType | undefined = useContext<UploadContextType | undefined>(UploadContext);
  if (context === undefined) {
    throw new Error(`useUploads must be used within a UploadsProvider`);
  }
  return context;
};

export { UploadProvider, useUploads };
