import { PlayerActions } from "video-react";

export const FRAME_TIME = 1 / 28;

export const frameBack = (actions?: PlayerActions) => {
  if (!!actions) {
    actions.pause();
    actions.replay(FRAME_TIME);
  }
};

export const frameForward = (actions?: PlayerActions) => {
  if (!!actions) {
    actions.pause();
    actions.forward(FRAME_TIME);
  }
};
