export const RouteConstants = {
  WILDCARD: "*",
  BASE: "/",
  SEARCH: "/search",
  PROFILE: "/player/:playerId",
  LOGIN: "/login",
  LOGIN_CALLBACK: "/login/callback",
  UPLOADS: "/uploads",
  VIDEO: "/video/:videoId",
  VIDEO_PREVIEW: "/video/preview/:videoId",
  FOLDERS: "/folders",
  ADMIN_REPORTING: "/admin/reporting",
  ADMIN_METADATA: "/admin/metadata",
  ADMIN_USER: "/admin/user",
  ADMIN_APPLICATION: "/admin/application",
  TAGS: "/tags",
  METADATA_EDIT: "/metadata/edit/:fileId",
};
