import { LoginCallback } from "@okta/okta-react";
import {
  AlertsFromProvider,
  BCR,
  BCRSuspense,
  ErrorCatcher,
  LoadingFromProvider,
  Traditional,
} from "best-common-react";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import ClipsNavigation from "./components/navigation/ClipsNavigation";
import { RouteConstants } from "./constants/RouteConstants";
import AdminMetadataContainer from "./containers/admin/AdminMetadataContainer";
import AdminReportingContainer from "./containers/admin/AdminReportingContainer";
import FoldersContainer from "./containers/FoldersContainer";
import LoginContainer from "./containers/LoginContainer";
import MetadataEditContainer from "./containers/MetadataEditContainer";
import ProfileContainer from "./containers/ProfileContainer";
import SearchContainer from "./containers/SearchContainer";
import TagsContainer from "./containers/TagsContainer";
import UploadsContainer from "./containers/UploadsContainer";
import VideoContainer from "./containers/VideoContainer";
import { useAuth } from "./contexts/AuthContext";
import HttpInterceptor from "./httpClients/HttpInterceptor";

type ContainerProps = {
  loggedIn: boolean;
};

const Container = styled.div<ContainerProps>`
  min-width: 100%;
  min-height: calc(100% - ${(props) => (props.loggedIn ? "69px" : "50px")});
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const App: React.FC = () => {
  const { loggedIn } = useAuth();
  return (
    <HttpInterceptor>
      <BCR theme={Traditional}>
        <Suspense fallback={<BCRSuspense />}>
          <ErrorCatcher appName="Clips" contactEmail="clipssupport@mlb.com">
            <div className="clips-body">
              <LoadingFromProvider />
              <AlertsFromProvider />
              <ClipsNavigation />
              <Container loggedIn={loggedIn}>
                <Routes>
                  <Route path={RouteConstants.LOGIN} element={<LoginContainer />} />
                  <Route path={RouteConstants.BASE} element={<SearchContainer />} />
                  <Route path={RouteConstants.SEARCH} element={<SearchContainer />} />
                  <Route path={RouteConstants.PROFILE} element={<ProfileContainer />} />
                  <Route path={RouteConstants.UPLOADS} element={<UploadsContainer />} />
                  <Route path={RouteConstants.VIDEO} element={<VideoContainer />} />
                  <Route path={RouteConstants.VIDEO_PREVIEW} element={<VideoContainer />} />
                  <Route path={RouteConstants.FOLDERS} element={<FoldersContainer />} />
                  <Route path={RouteConstants.ADMIN_REPORTING} element={<AdminReportingContainer />} />
                  <Route path={RouteConstants.ADMIN_METADATA} element={<AdminMetadataContainer />} />
                  <Route path={RouteConstants.TAGS} element={<TagsContainer />} />
                  <Route path={RouteConstants.METADATA_EDIT} element={<MetadataEditContainer />} />
                  <Route path={RouteConstants.LOGIN_CALLBACK} element={<LoginCallback />} />
                </Routes>
              </Container>
            </div>
          </ErrorCatcher>
        </Suspense>
      </BCR>
    </HttpInterceptor>
  );
};

export default App;
