import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { AccessTime, InsertDriveFile, LocationOn, Person, School, Videocam } from "@material-ui/icons";
import { formatDate } from "best-common-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getVideo } from "../../httpClients/ClipsApi";
import { BoxFileDTO } from "../../types/Video";
import { formatName } from "../../utils/PersonUtils";
import InlineLoading from "../loading/InlineLoading";
import VideoThumbnail from "./VideoThumbnail";

type RelatedVideoProps = {
  fileId: number | string;
};

const RelatedVideo: React.FC<RelatedVideoProps> = ({ fileId }) => {
  const [video, setVideo] = useState<BoxFileDTO | undefined>(undefined);

  const fetchData = async () => {
    const result: BoxFileDTO = await getVideo(fileId);
    setVideo(result);
  };

  useEffect(() => {
    void fetchData();
  }, []);

  return (
    <Card className="mt-2">
      {video ? (
        <Link to={`/video/${fileId}`}>
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <VideoThumbnail src={video.boxSharedLink.downloadURL} />
              </Grid>
              <Grid item xs={6}>
                <Typography color="textSecondary">
                  <InsertDriveFile fontSize="small" className="me-2" />
                  {video.fileName}
                </Typography>
                <Typography color="textSecondary">
                  <Person fontSize="small" className="me-2" />
                  {formatName(video.metadata.firstName, video.metadata.lastName)}
                </Typography>
                <Typography color="textSecondary">
                  <AccessTime fontSize="small" className="me-2" />
                  {formatDate(video.metadata.filmDate)}
                </Typography>
                <Typography color="textSecondary">
                  <School fontSize="small" className="me-2" />
                  {video.metadata.school}
                </Typography>
                <Typography color="textSecondary">
                  <LocationOn fontSize="small" className="me-2" />
                  {video.metadata.city
                    ? video.metadata.city + ", "
                    : "" + video.metadata.state
                    ? video.metadata.state
                    : ""}
                </Typography>
                <Typography color="textSecondary">
                  <Videocam fontSize="small" className="me-2" />
                  {video.metadata.source}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Link>
      ) : (
        <div className="d-flex justify-content-center">
          <InlineLoading />
        </div>
      )}
    </Card>
  );
};

export default RelatedVideo;
