import { DataTable, DataTableColumn, DateTimeFormatter, FormatterType, Icon } from "best-common-react";
import React from "react";
import { Link } from "react-router-dom";
import { useFolders } from "../../contexts/FoldersContext";
import { SelectableFolder } from "../../types/Folders";
import BoxLinkFormatter from "./BoxLinkFormatter";

const PreviewFormatter: React.FC<FormatterType<SelectableFolder>> = ({ value }) => (
  <Link to={`/video/preview/${value}`}>
    <Icon iconName="fa-file-video" />
  </Link>
);

const Columns: DataTableColumn<SelectableFolder>[] = [
  {
    name: " ",
    key: "fileId",
    readonlyFormatter: PreviewFormatter,
    cellClass: "text-center",
    width: 50,
  },
  {
    name: " ",
    key: "boxSharedLink",
    readonlyFormatter: BoxLinkFormatter,
    width: 50,
    cellClass: "text-center",
  },
  {
    name: "File Name",
    key: "fileName",
  },
  {
    name: "Upload Date",
    key: "uploadDate",
    readonlyFormatter: DateTimeFormatter,
  },
];

const PendingFolders = () => {
  const { items, setSelected, setItems } = useFolders();

  const onSelectionChanged = (rows) => {
    const newItems = [...items];
    rows.forEach((r) => {
      const index = r.rowIdx;
      newItems[index].checked = !newItems[index].checked;
    });
    const selectedItems = newItems.filter((item) => item.checked);
    setSelected(selectedItems);
    setItems(newItems);
  };

  return (
    <div className="p-2">
      <DataTable
        data={items}
        columns={Columns}
        rowSelection={{
          canSelect: () => true,
          showCheckbox: true,
          onRowsSelected: onSelectionChanged,
          onRowsDeselected: onSelectionChanged,
          selectBy: {
            isSelectedKey: "checked",
          },
        }}
      />
    </div>
  );
};

export default PendingFolders;
