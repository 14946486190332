import { Button, StickyFooterButtons } from "best-common-react";
import React from "react";
import { useFolders } from "../../contexts/FoldersContext";

const FoldersFooter = () => {
  const { activeTab, validToMove, moveFilesCall } = useFolders();
  return (
    <StickyFooterButtons>
      {activeTab?.name === "Pending" && (
        <Button variant="primary" disabled={!validToMove} onClick={moveFilesCall}>
          Move Files to Final
        </Button>
      )}
    </StickyFooterButtons>
  );
};

export default FoldersFooter;
