import { Icon } from "best-common-react";
import React, { useCallback } from "react";
import { PlayerActions } from "video-react";
import { frameBack, frameForward } from "../../../utils/VideoUtils";

type FrameByFrameProps = {
  actions?: PlayerActions;
};

const FrameByFrame: React.FC<FrameByFrameProps> = ({ actions }) => {
  const frameBackClick = useCallback(() => {
    frameBack(actions);
  }, []);

  const frameForwardClick = useCallback(() => {
    frameForward(actions);
  }, []);

  return (
    <div>
      <button onClick={frameBackClick} tabIndex={0} className="video-react-control video-react-button">
        <Icon iconName="fa-angle-double-left" styles={{ color: "white" }} />
      </button>
      <button onClick={frameForwardClick} tabIndex={0} className="video-react-control video-react-button">
        <Icon iconName="fa-angle-double-right" styles={{ color: "white" }} />
      </button>
    </div>
  );
};

export default FrameByFrame;
