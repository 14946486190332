import { Grid } from "@material-ui/core";
import { useLoading } from "best-common-react";
import React, { useEffect, useState } from "react";
import { matchRoutes } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import Protected from "../components/protected/Protected";
import RelatedVideos from "../components/video/RelatedVideos";
import VideoBody from "../components/video/VideoBody";
import { RouteConstants } from "../constants/RouteConstants";
import { getVideoPreview, getVideoWithRelated } from "../httpClients/ClipsApi";
import { BoxFileDTO } from "../types/Video";

const VideoContainer: React.FC = () => {
  const { videoId } = useParams();
  const currentLocation = useLocation();
  const isPreview = matchRoutes([{ path: RouteConstants.VIDEO_PREVIEW }], currentLocation);
  const { setLoading } = useLoading();
  const [video, setVideo] = useState<BoxFileDTO | undefined>(undefined);

  const getVideoData = async () => {
    if (!!videoId) {
      try {
        setLoading(true);
        const result: BoxFileDTO = isPreview
          ? await getVideoPreview(parseInt(videoId))
          : await getVideoWithRelated(parseInt(videoId));
        setVideo(result);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    void getVideoData();
  }, [videoId]);

  return (
    <div>
      <Grid container spacing={8}>
        <Grid item xs={12} lg={9}>
          {!!video && <VideoBody video={video} setVideo={setVideo} />}
        </Grid>
        {!isPreview && (
          <Grid item xs={12} lg={3}>
            {!!video && <RelatedVideos relatedFiles={video.relatedFiles} />}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Protected(VideoContainer);
