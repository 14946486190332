import { AlertConstants, removeLocalStorageItem, useAlerts, ValueOpt } from "best-common-react";
import React, { useContext, useEffect, useState } from "react";
import { MetadataConstants } from "../constants/MetadataConstants";
import { getMetadataFields, getTags } from "../httpClients/ClipsApi";
import {
  AdvancedSearchOptions,
  Bats,
  Classes,
  Countries,
  Heights,
  MetadataFields,
  Org,
  PlayerStatus,
  Position,
  SchoolType,
  States,
  Throws,
} from "../types/Metadata";
import { Tags } from "../types/Tags";
import { useAuth } from "./AuthContext";

type MetadataContextType = {
  tags: Tags[];
  tagOptions: ValueOpt<Tags>[];
  orgOptions: ValueOpt<Org>[];
  positionOptions: ValueOpt<Position>[];
  playerStatusOptions: ValueOpt<PlayerStatus>[];
  advancedSearchOptions: ValueOpt<AdvancedSearchOptions>[];
  batsOptions: ValueOpt<Bats>[];
  heightOptions: ValueOpt<Heights>[];
  countryOptions: ValueOpt<Countries>[];
  classOptions: ValueOpt<Classes>[];
  schoolTypeOptions: ValueOpt<SchoolType>[];
  stateOptions: ValueOpt<States>[];
  throwsOptions: ValueOpt<Throws>[];
  getValueFromOptions: (options: ValueOpt<any>[], value: any) => any;
  refetchTags: () => Promise<any>;
  refetchData: () => void;
  convertToLabelValue: (value: any) => ValueOpt<any>;
};

const MetadataContext = React.createContext<MetadataContextType | undefined>(undefined);

const MetadataProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { userInfo, loggedIn } = useAuth();
  const { addAlert } = useAlerts();
  const [tags, setTags] = useState<Tags[]>([]);
  const [metadata, setMetadata] = useState<MetadataFields | undefined>(undefined);
  const [orgOptions, setOrgOptions] = useState<ValueOpt<Org>[]>([]);
  const [tagOptions, setTagOptions] = useState<ValueOpt<Tags>[]>([]);
  const [positionOptions, setPositionOptions] = useState<ValueOpt<Position>[]>([]);
  const [playerStatusOptions, setPlayerStatusOptions] = useState<ValueOpt<PlayerStatus>[]>([]);
  const [advancedSearchOptions, setAdvancedSearchOptions] = useState<ValueOpt<AdvancedSearchOptions>[]>([]);
  const [batsOptions, setBatsOptions] = useState<ValueOpt<Bats>[]>([]);
  const [heightOptions, setHeightOptions] = useState<ValueOpt<Heights>[]>([]);
  const [countryOptions, setCountryOptions] = useState<ValueOpt<Countries>[]>([]);
  const [classOptions, setClassOptions] = useState<ValueOpt<Classes>[]>([]);
  const [schoolTypeOptions, setSchoolTypeOptions] = useState<ValueOpt<SchoolType>[]>([]);
  const [stateOptions, setStateOptions] = useState<ValueOpt<States>[]>([]);
  const [throwsOptions, setThrowsOptions] = useState<ValueOpt<Throws>[]>([]);

  const refetchMetadata = async () => {
    try {
      const result: MetadataFields = await getMetadataFields();
      setMetadata(result);
      localStorage.setItem(MetadataConstants.localStorage.fields, JSON.stringify(result));
    } catch (e) {
      addAlert({
        type: AlertConstants.TYPES.DANGER,
        text: "Error loading metadata",
      });
    }
  };

  const getMetadata = async () => {
    if (localStorage.getItem(MetadataConstants.localStorage.fields)) {
      setMetadata(JSON.parse(localStorage.getItem(MetadataConstants.localStorage.fields) as string) as MetadataFields);
    } else {
      refetchMetadata();
    }
  };

  const refetchTags = async () => {
    if (!!userInfo?.org?.name) {
      try {
        const result: Tags[] = await getTags(userInfo?.org?.name);
        setTags(result);
        localStorage.setItem(MetadataConstants.localStorage.tags, JSON.stringify(result));
      } catch (e) {
        addAlert({
          type: AlertConstants.TYPES.DANGER,
          text: "Error loading tags",
        });
      }
    }
  };

  const fetchTags = async () => {
    if (localStorage.getItem(MetadataConstants.localStorage.tags)) {
      setTags(JSON.parse(localStorage.getItem(MetadataConstants.localStorage.tags) as string) as Tags[]);
    } else {
      refetchTags();
    }
  };

  const refetchData = () => {
    removeLocalStorageItem(MetadataConstants.localStorage.tags);
    removeLocalStorageItem(MetadataConstants.localStorage.fields);
    refetchMetadata();
    refetchTags();
  };

  const getOrgOptions = (metadata: MetadataFields): ValueOpt<Org>[] => {
    return metadata.orgs
      ? metadata.orgs
          .filter((o: Org) => {
            if (userInfo?.org?.name === "MLSB") {
              return true;
            } else {
              return userInfo?.org?.name === o.name || o.name === "MLSB";
            }
          })
          .sort((a: Org, b: Org) => (a.name > b.name ? 1 : -1))
          .map((o: Org) => ({ value: o, label: o.name }))
      : [];
  };

  const getPositionOptions = (metadata: MetadataFields): ValueOpt<Position>[] => {
    return !!metadata.positions
      ? metadata.positions
          .sort((a: Position, b: Position) => (a.key > b.key ? 1 : -1))
          .map((p: Position) => ({ value: p, label: p.key }))
      : [];
  };

  const getPlayerStatusOptions = (metadata: MetadataFields): ValueOpt<PlayerStatus>[] => {
    return metadata.playerStatuses ? metadata.playerStatuses.map((p: PlayerStatus) => ({ value: p, label: p })) : [];
  };

  const getAdvancedSearchOptions = (metadata: MetadataFields): ValueOpt<AdvancedSearchOptions>[] => {
    return metadata.advancedSearchOptions
      ? metadata.advancedSearchOptions
          .sort((a: AdvancedSearchOptions, b: AdvancedSearchOptions) => (a.name > b.name ? 1 : -1))
          .map((p) => ({ label: p.name, value: p }))
      : [];
  };

  const getBatsOptions = (metadata: MetadataFields): ValueOpt<Bats>[] => {
    return metadata.bats ? metadata.bats.map((p: Bats) => ({ value: p, label: p })) : [];
  };

  const getCountryOptions = (metadata: MetadataFields): ValueOpt<Countries>[] => {
    return metadata.countries
      ? metadata.countries
          .sort((a: Countries, b: Countries) => (a.name > b.name ? 1 : -1))
          .map((o: Countries) => ({ label: o.name, value: o }))
      : [];
  };

  const getHeightOptions = (metadata: MetadataFields): ValueOpt<Heights>[] => {
    return metadata.heights ? metadata.heights.map((o: Heights) => ({ label: o.key, value: o })) : [];
  };

  const getClassOptions = (metadata: MetadataFields): ValueOpt<Classes>[] => {
    return metadata.classes
      ? metadata.classes
          .sort((a: Classes, b: Classes) => (a.name > b.name ? 1 : -1))
          .map((o: Classes) => ({ value: o, label: o.name }))
      : [];
  };

  const getSchoolTypesOptions = (metadata: MetadataFields): ValueOpt<SchoolType>[] => {
    return metadata.schoolTypes
      ? metadata.schoolTypes
          .sort((a: SchoolType, b: SchoolType) => (a.key > b.key ? 1 : -1))
          .map((o: SchoolType) => ({ value: o, label: o.key }))
      : [];
  };

  const getStatesOptions = (metadata: MetadataFields): ValueOpt<States>[] => {
    return metadata.states
      ? metadata.states
          .sort((a: States, b: States) => (a.key > b.key ? 1 : -1))
          .map((o: States) => ({ value: o, label: o.key }))
      : [];
  };

  const getThrowsOptions = (metadata: MetadataFields): ValueOpt<Throws>[] => {
    return metadata.throwSide ? metadata.throwSide.map((o: Throws) => ({ value: o, label: o })) : [];
  };

  const getValueFromOptions = (options: ValueOpt<any>[], value: any): object => {
    if (!!options?.length && !!value) {
      const option = options.find((opt) => opt.value === value);
      return !!option ? option : {};
    }
    return {};
  };

  const convertToLabelValue = (value: any): ValueOpt<any> => ({ value: value, label: value });

  useEffect(() => {
    if (loggedIn) {
      void getMetadata();
      void fetchTags();
    }
  }, [userInfo, loggedIn]);

  useEffect(() => {
    if (!!metadata) {
      setOrgOptions(getOrgOptions(metadata));
      setPositionOptions(getPositionOptions(metadata));
      setPlayerStatusOptions(getPlayerStatusOptions(metadata));
      setAdvancedSearchOptions(getAdvancedSearchOptions(metadata));
      setBatsOptions(getBatsOptions(metadata));
      setHeightOptions(getHeightOptions(metadata));
      setCountryOptions(getCountryOptions(metadata));
      setClassOptions(getClassOptions(metadata));
      setSchoolTypeOptions(getSchoolTypesOptions(metadata));
      setStateOptions(getStatesOptions(metadata));
      setThrowsOptions(getThrowsOptions(metadata));
    }
  }, [metadata]);

  useEffect(() => {
    setTagOptions(tags.sort((a: Tags, b: Tags) => (a.tag > b.tag ? 1 : -1)).map((t) => ({ label: t.tag, value: t })));
  }, [tags]);

  return (
    <MetadataContext.Provider
      value={{
        tags,
        tagOptions,
        orgOptions,
        positionOptions,
        playerStatusOptions,
        advancedSearchOptions,
        batsOptions,
        heightOptions,
        countryOptions,
        classOptions,
        schoolTypeOptions,
        stateOptions,
        throwsOptions,
        getValueFromOptions,
        refetchTags,
        refetchData,
        convertToLabelValue,
      }}
    >
      {children}
    </MetadataContext.Provider>
  );
};

const useMetadata = (): MetadataContextType => {
  const context = useContext<MetadataContextType | undefined>(MetadataContext);
  if (context === undefined) {
    throw new Error(`useMetadata must be used within a MetadataProvider`);
  }
  return context;
};

export { MetadataProvider, useMetadata };
