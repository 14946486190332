import { Card } from "best-common-react";
import React from "react";
import FoldersBody from "../components/folders/FoldersBody";
import FoldersFooter from "../components/folders/FoldersFooter";
import Protected from "../components/protected/Protected";
import { FoldersProvider } from "../contexts/FoldersContext";

const FoldersContainer: React.FC = () => (
  <FoldersProvider>
    <Card>
      <Card.Header>Folders</Card.Header>
      <Card.Body>
        <FoldersBody />
      </Card.Body>
      <Card.Footer>
        <FoldersFooter />
      </Card.Footer>
    </Card>
  </FoldersProvider>
);

export default Protected(FoldersContainer);
