import { FormatterType, Icon } from "best-common-react";
import React from "react";
import { SearchResult } from "../../../types/Search";

const ExternalVideoLinkFormatter: React.FC<FormatterType<SearchResult>> = ({ value }) => {
  const to = `/video/${value}`;
  return (
    <Icon
      iconName="fa-external-link-alt"
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        window.open(to, "_blank");
      }}
    />
  );
};

export default ExternalVideoLinkFormatter;
