import { Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import RelatedVideo from "./RelatedVideo";

type RelatedVideosProps = {
  relatedFiles: string[] | number[];
};

const RelatedVideos: React.FC<RelatedVideosProps> = ({ relatedFiles }) => (
  <div>
    <Typography variant="h5">Related Videos</Typography>
    <Divider />
    <Grid container>
      {!!relatedFiles?.length ? (
        <>
          {relatedFiles.map((fileId: string | number) => (
            <Grid item sm={6} lg={12} key={fileId}>
              <RelatedVideo key={fileId} fileId={fileId} />
            </Grid>
          ))}
        </>
      ) : (
        <div> There are no related videos</div>
      )}
    </Grid>
  </div>
);

export default RelatedVideos;
