import {
  Accordion,
  AttributeHeader,
  AttributeValue,
  DataTable,
  DataTableColumn,
  DateTimeFormatter,
  SortDirection,
  SortFilters,
  tableSort,
  urlReplace,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { RouteConstants } from "../../../constants/RouteConstants";
import { SearchResult, SearchResultGrouped } from "../../../types/Search";
import ExternalVideoLinkFormatter from "./ExternalVideoLinkFormatter";
import TagsFormatter from "./TagsFormatter";

const Columns: DataTableColumn<SearchResult>[] = [
  {
    key: "fileId",
    name: "",
    readonlyFormatter: ExternalVideoLinkFormatter,
    width: 45,
  },
  {
    key: "fileName",
    name: "File Name",
    sortable: true,
    minWidth: 400,
  },
  {
    key: "source",
    name: "Source",
    sortable: true,
    width: 75,
  },
  {
    key: "school",
    name: "School",
    sortable: true,
    width: 250,
  },
  {
    key: "city",
    name: "City",
    sortable: true,
    width: 250,
  },
  {
    key: "state",
    name: "State",
    sortable: true,
    width: 75,
  },
  {
    key: "class",
    name: "Class",
    sortable: true,
    width: 100,
  },
  {
    key: "playerStatus",
    name: "Status",
    sortable: true,
    width: 75,
  },
  {
    key: "filmDate",
    name: "Film Date",
    readonlyFormatter: DateTimeFormatter,
    sortable: true,
    width: 150,
  },
  {
    key: "tags",
    name: "Tags",
    cellClass: "text-center",
    readonlyFormatter: TagsFormatter,
    width: 75,
  },
];
type AttributeValueHolderStyleProps = {
  width?: number;
};

const AttributeValueHolder = styled.div.attrs(() => ({
  className: "m-1",
}))<AttributeValueHolderStyleProps>`
  width: ${(props) => (props.width ? props.width : 150)}px;
`;

type PlayerSearchResultProps = {
  data: SearchResultGrouped;
};

const PlayerSearchResult: React.FC<PlayerSearchResultProps> = ({ data }) => {
  const navigate = useNavigate();
  const [sortFilters, setSortFilters] = useState<SortFilters<SearchResult>>({
    direction: "ASC",
    key: "playerName",
  });
  const [sortedData, setSortedData] = useState<SearchResult[]>([]);

  useEffect(() => {
    setSortedData(tableSort(data.videos, sortFilters));
  }, [data, sortFilters]);

  return (
    <Accordion accordionKey={data.playerName}>
      <Accordion.Header>
        <div className="d-flex align-items-center justify-content-between">
          <AttributeValueHolder width={200}>
            <AttributeHeader>Player Name</AttributeHeader>
            <AttributeValue>{data.playerName}</AttributeValue>
          </AttributeValueHolder>
          <AttributeValueHolder width={150}>
            <AttributeHeader>School</AttributeHeader>
            <AttributeValue>{data.school}</AttributeValue>
          </AttributeValueHolder>
          <AttributeValueHolder width={100}>
            <AttributeHeader>City</AttributeHeader>
            <AttributeValue>{data.city}</AttributeValue>
          </AttributeValueHolder>
          <AttributeValueHolder width={50}>
            <AttributeHeader>State</AttributeHeader>
            <AttributeValue>{data.state}</AttributeValue>
          </AttributeValueHolder>
          <AttributeValueHolder width={50}>
            <AttributeHeader>Class</AttributeHeader>
            <AttributeValue>{data.class}</AttributeValue>
          </AttributeValueHolder>
          <AttributeValueHolder width={50}>
            <AttributeHeader>Player Status</AttributeHeader>
            <AttributeValue>{data.playerStatus}</AttributeValue>
          </AttributeValueHolder>
          <AttributeValueHolder width={60}>
            <AttributeHeader>EBIS</AttributeHeader>
            <AttributeValue>{data.ebis}</AttributeValue>
          </AttributeValueHolder>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <DataTable
          data={sortedData}
          columns={Columns}
          sortFunction={(key: keyof SearchResult, direction: SortDirection) => {
            setSortFilters({ direction: direction, key: key });
          }}
          sortColumn={sortFilters["key"]}
          sortDirection={sortFilters["direction"]}
          tableHeights={{
            maxTableHeight: 200,
          }}
          rowClick={{
            onClick: (_index: number, data: SearchResultGrouped) => {
              navigate(urlReplace(RouteConstants.VIDEO, { videoId: data.fileId }));
            },
          }}
        />
      </Accordion.Body>
    </Accordion>
  );
};

export default PlayerSearchResult;
