const Domestic: [string[]] = [
  [
    "PLAYER LAST NAME",
    "PLAYER FIRST NAME",
    "ROSTER NAME",
    "PLAYER ID",
    "EBIS ID",
    "DPL ID",
    "SCHOOL",
    "CITY",
    "STATE",
    "OFP",
    "POS",
    "BATS",
    "THROWS",
    "HT",
    "WT",
    "ELIG",
    "SCHOOL TYPE",
    "CLASS",
    "DOB",
    "TAPED",
    "UNI",
    "VIDEO BY",
    "COMMENTS",
    "PLAYER STATUS",
    "TAGS",
    "FILE NAME",
    "ASSETID",
  ],
];

const International: [string[]] = [
  [
    "PLAYER LAST NAME",
    "EXTENDED LAST NAME",
    "PLAYER FIRST NAME",
    "MIDDLE NAME",
    "ROSTER NAME",
    "PLAYER ID",
    "EBIS ID",
    "CITY",
    "COUNTRY",
    "POS",
    "BATS",
    "THROWS",
    "HT",
    "WT",
    "ELIG",
    "DOB",
    "TAPED",
    "UNI",
    "VIDEO BY",
    "COMMENTS",
    "HOME ADDRESS",
    "PHONE NUMBER",
    "E-MAIL",
    "TRAINER NAME",
    "PLAYER STATUS",
    "TAGS",
    "FILE NAME",
    "ASSETID",
  ],
];

export const MetadataConstants = {
  localStorage: {
    tags: "TAGS",
    fields: "FIELDS",
  },
  keyMappings: {
    firstName: "PLAYER FIRST NAME",
    lastName: "PLAYER LAST NAME",
    playerId: "PLAYER ID",
    ebis: "EBIS ID",
    dplId: "DPL ID",
    position: "POS",
    school: "SCHOOL",
    city: "CITY",
    state: "STATE",
    bats: "BATS",
    throws: "THROWS",
    height: "HT",
    weight: "WT",
    eligibility: "ELIG",
    schoolType: "SCHOOL TYPE",
    classification: "CLASS",
    birthDate: "DOB",
    uni: "UNI",
    comments: "COMMENTS",
    filmDate: "TAPED",
    playerStatus: "PLAYER STATUS",
    country: "COUNTRY",
    homeAddress: "HOME ADDRESS",
    phoneNumber: "PHONE NUMBER",
    email: "E-MAIL",
    trainerName: "TRAINER NAME",
    fileName: "FILE NAME",
    source: "SOURCE",
    tags: "TAGS",
    clips1assetid: "ASSETID",
    ofp: "OFP",
    rosterName: "ROSTER NAME",
    videoBy: "VIDEO BY",
    extendedLastName: "EXTENDED LAST NAME",
    middleName: "MIDDLE NAME",
  },
  DOMESTIC: Domestic,
  INTERNATIONAL: International,
};
