import { Typography } from "best-common-react";
import React from "react";
import { useAdminReporting } from "../../../contexts/ admin/AdminReportingContext";
import { UserDownloadsByOrg } from "../../../types/Reports";
import AdminReportingForm from "./AdminReportingForm";
import AdminReportingResults from "./AdminReportingResults";

const AdminUserViews = () => {
  const { data } = useAdminReporting();
  return (
    <div className="p-2">
      <Typography variant="h5">User Views</Typography>
      <AdminReportingForm />
      <AdminReportingResults data={data as UserDownloadsByOrg[]} defaultSortKey="downloadDate" />
    </div>
  );
};

export default AdminUserViews;
