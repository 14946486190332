import { Button, StickyFooterButtons } from "best-common-react";
import React from "react";
import { useUploads } from "../../contexts/UploadContext";

const UploadsFooter = () => {
  const { canSave, upload } = useUploads();
  return (
    <StickyFooterButtons>
      <Button variant="primary" onClick={upload} disabled={!canSave}>
        Upload
      </Button>
    </StickyFooterButtons>
  );
};

export default UploadsFooter;
