import { Button, Email, Icon, Modal, Typography, useLoading } from "best-common-react";
import React, { useEffect, useState } from "react";
import isEmail from "validator/lib/isEmail";
import { shareFile } from "../../httpClients/ClipsApi";

type VideoShareModalProps = {
  videoId: number | string;
  open: boolean;
  close: () => void;
};

const VideoShareModal: React.FC<VideoShareModalProps> = ({ videoId, open, close }) => {
  const { setLoading } = useLoading();
  const [shareEmails, setShareEmails] = useState<string[]>([]);
  const [canShare, setCanShare] = useState<boolean>(false);
  const addShareEmail = () => {
    setShareEmails([...shareEmails, ""]);
  };

  const removeEmail = (index: number) => {
    const emails = [...shareEmails];
    emails.splice(index, 1);
    setShareEmails(emails);
  };

  const updateShareEmails = (index: number, value: string) => {
    const newEmails = [...shareEmails];
    newEmails[index] = value;
    setShareEmails(newEmails);
  };

  const share = async () => {
    if (!!videoId && !!canShare) {
      try {
        setLoading(true);
        await shareFile(videoId, shareEmails);
        close();
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    let canShareVideo = false;
    if (!!shareEmails.length) {
      const invalidEmails = shareEmails.filter((email) => {
        return !isEmail(email);
      });
      canShareVideo = !invalidEmails.length;
    }
    setCanShare(canShareVideo);
  }, [shareEmails]);

  return (
    <Modal show={open}>
      <Modal.Header
        close={() => {
          close();
        }}
      >
        Share Video
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="d-flex justify-content-between mb-2">
            <Typography variant="body2">Emails to Share With</Typography>
            <Icon iconName="fa-plus" onClick={addShareEmail} />
          </div>
          {shareEmails.map((e, index) => (
            <div
              /* eslint-disable */
              key={index}
              className="w-100 d-flex align-items-center mb-2"
            >
              <div className="w-100">
                <Email
                  id={`email-number-${index}`}
                  value={e}
                  placeholder="Recipient Email"
                  onChange={(value) => updateShareEmails(index, value)}
                />
              </div>
              <Icon iconName="fa-trash" className="ms-2" onClick={() => removeEmail(index)} />
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button variant="primary" onClick={share} disabled={!canShare} className="me-2">
            Share
          </Button>
          <Button
            variant="default"
            onClick={(event) => {
              event.stopPropagation();
              close();
            }}
          >
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default VideoShareModal;
