import { Tab, useLoading } from "best-common-react";
import React, { useContext, useEffect, useState } from "react";
import { getFolderFiles, moveFiles } from "../httpClients/ClipsApi";
import { SelectableFolder } from "../types/Folders";
import { BoxFileLiteDTO } from "../types/Video";
import { useAuth } from "./AuthContext";

const getFileIdsToMove = (items: SelectableFolder[]) => {
  return items.filter((f) => f.checked).map((f) => f.fileId);
};

type FoldersContextType = {
  items: SelectableFolder[];
  activeTab: Tab;
  validToMove: boolean;
  selected: SelectableFolder[];
  setItems: (value: SelectableFolder[]) => void;
  setSelected: (value: SelectableFolder[]) => void;
  setActiveTab: (value: Tab) => void;
  moveFilesCall: () => Promise<void>;
};

const FoldersContext = React.createContext<FoldersContextType | undefined>(undefined);

const FoldersProvider = ({ children }) => {
  const { setLoading } = useLoading();
  const { clipsFolders = {} } = useAuth();
  const { uploadsFolderId, pendingFolderId, finalFolderId } = clipsFolders;
  const [items, setItems] = useState<SelectableFolder[]>([]);
  const [activeTab, setActiveTab] = useState<Tab | undefined>(undefined);
  const [validToMove, setValidToMove] = useState<boolean>(true);
  const [selected, setSelected] = useState<SelectableFolder[]>([]);

  const getUploadsFolderItems = async () => {
    try {
      if (!!uploadsFolderId) {
        setLoading(true);
        const result: BoxFileLiteDTO[] = await getFolderFiles(uploadsFolderId);
        setItems(result);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getPendingFolderItems = async () => {
    try {
      if (!!pendingFolderId) {
        setLoading(true);
        const result: BoxFileLiteDTO[] = await getFolderFiles(pendingFolderId);
        setItems(result);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const moveFilesCall = async () => {
    try {
      if (!!finalFolderId) {
        setLoading(true);
        await moveFiles(finalFolderId, getFileIdsToMove(items));
        await getPendingFolderItems();
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    setItems([]);
    if (!!activeTab) {
      if (activeTab.name === "Uploads") {
        if (!!uploadsFolderId) {
          void getUploadsFolderItems();
        }
      } else if (activeTab.name === "Pending") {
        if (!!pendingFolderId) {
          void getPendingFolderItems();
        }
      }
    }
  }, [activeTab, uploadsFolderId, pendingFolderId]);

  useEffect(() => {
    setSelected([]);
  }, [activeTab]);

  useEffect(() => {
    setValidToMove(!!selected?.length);
  }, [selected]);

  return (
    <FoldersContext.Provider
      value={{ items, activeTab, validToMove, selected, setItems, setSelected, setActiveTab, moveFilesCall }}
    >
      {children}
    </FoldersContext.Provider>
  );
};

const useFolders = () => {
  const context: FoldersContextType | undefined = useContext<FoldersContextType | undefined>(FoldersContext);
  if (context === undefined) {
    throw new Error(`useFolders must be used within a FoldersProvider`);
  }
  return context;
};

export { FoldersProvider, useFolders };
