import { Card } from "best-common-react";
import React from "react";
import AdminReportingBody from "../../components/admin/reporting/AdminReportingBody";
import AdminReportingFooter from "../../components/admin/reporting/AdminReportingFooter";
import AdminProtected from "../../components/protected/AdminProtected";
import { AdminReportingProvider } from "../../contexts/ admin/AdminReportingContext";

const AdminReportingContainer = () => (
  <AdminReportingProvider>
    <Card>
      <Card.Header>Admin Reporting</Card.Header>
      <Card.Body>
        <AdminReportingBody />
      </Card.Body>
      <Card.Footer>
        <AdminReportingFooter />
      </Card.Footer>
    </Card>
  </AdminReportingProvider>
);

export default AdminProtected(AdminReportingContainer);
