import { DateRange, downloadFile, groupBy, removeNullFields, Tab, useLoading, ValueOpt } from "best-common-react";
import React, { useContext, useEffect, useState } from "react";
import { exportSearchResultsData, getMetadataTemplate, search } from "../httpClients/ClipsApi";
import { Pagination } from "../types/Pagination";
import {
  AdvancedSearchValue,
  AdvanceSearchSelectValue,
  SearchField,
  SearchResult,
  SearchResultGrouped,
} from "../types/Search";
import { Tags } from "../types/Tags";
import { BoxFileDTO, BoxSearchResultsDTO, ClipsMetadataDTO } from "../types/Video";
import { buildGroupKey, convertSearchResult } from "../utils/SearchUtils";

const createMetadata = (fields: SearchField[]): ClipsMetadataDTO => {
  const metadata = {};
  fields.forEach((f: SearchField) => {
    const value: AdvancedSearchValue = f.value;
    if ((value as DateRange<any>).start || (value as DateRange<any>).end) {
      metadata[f.key + "Range"] = {
        start: (value as DateRange<any>).start,
        end: (value as DateRange<any>).end,
      };
    } else if ((value as ValueOpt<AdvanceSearchSelectValue>).value) {
      metadata[f.key] = (value as ValueOpt<AdvanceSearchSelectValue>).value;
    } else if (value instanceof Array) {
      metadata[f.key] = (value as ValueOpt<AdvanceSearchSelectValue>[]).map(
        (v: ValueOpt<AdvancedSearchValue>) => v.value,
      );
    } else {
      metadata[f.key] = value;
    }
  });
  return metadata;
};

const massageResultsData = (data: BoxFileDTO[]): SearchResultGrouped[] => {
  const tempResults: SearchResult[] = data
    .map((sr: BoxFileDTO) => convertSearchResult(sr))
    .map((a: SearchResult) => buildGroupKey(a));
  const groupKeys = groupBy(tempResults, "groupKey");
  const groupedResults = Object.entries(groupKeys).map((field) => {
    const items = field[1];
    const video = { ...items[0] };
    const result = { ...video, videos: items };
    return result;
  });
  return groupedResults;
};

const getValidFields = (fields: SearchField[]) => fields.filter((f) => f.value);

const newField: SearchField = { key: "", value: null };
const initialPagination: Pagination = { pageNumber: 0, pageSize: 100, totalCount: 0 };

type SearchContextType = {
  metadata: ClipsMetadataDTO;
  activeTab: Tab | undefined;
  setActiveTab: (value: Tab) => void;
  searchResults: SearchResultGrouped[];
  selectedTags: ValueOpt<Tags>[];
  setSelectedTags: (value: ValueOpt<Tags>[]) => void;
  hasSearched: boolean;
  fields: SearchField[];
  canSearch: boolean;
  pagination: Pagination;
  updateMetadata: (key: string, value: any) => void;
  search: () => void;
  resetForm: () => void;
  addNewField: () => void;
  updateField: (index: number, field: SearchField) => void;
  removeField: (index: number) => void;
  exportSearchResults: () => Promise<any>;
  setPagination: (value: Pagination) => void;
};

const SearchContext = React.createContext<SearchContextType | undefined>(undefined);

const SearchProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const { setLoading } = useLoading();
  const [activeTab, setActiveTab] = useState<Tab | undefined>(undefined);
  const [searchResults, setSearchResults] = useState<SearchResultGrouped[]>([]);
  const [metadata, setMetadata] = useState<ClipsMetadataDTO>({});
  const [pagination, setPagination] = useState<Pagination>(initialPagination);
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [canSearch, setCanSearch] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<ValueOpt<Tags>[]>([]);
  const [fields, setFields] = useState<SearchField[]>([
    {
      key: "lastName",
      value: "",
    },
  ]);

  const getMetadataTemplateCall = async () => {
    const result = await getMetadataTemplate();
    setMetadata(result);
  };

  const simpleSearch = async () => {
    if (canSearch) {
      try {
        setLoading(true);
        const tags: string[] = !!selectedTags?.length ? selectedTags.map((t: ValueOpt<Tags>) => t.value?.tag) : [];
        const metadataSearch: ClipsMetadataDTO = { ...metadata, tags: tags };
        const result = await search(metadataSearch, pagination.pageNumber);
        setSearchResults(massageResultsData(result.results));
        setPagination({ ...pagination, totalCount: result.fullSize });
        setHasSearched(true);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
    return Promise.resolve();
  };

  const advancedSearch = async () => {
    if (canSearch) {
      try {
        setLoading(true);
        const finalFields = getValidFields(fields);
        setFields(finalFields);
        if (finalFields.length) {
          const metadata = createMetadata(finalFields);
          const result: BoxSearchResultsDTO = await search(metadata, pagination.pageNumber);
          setSearchResults(massageResultsData(result.results));
          setPagination({ ...pagination, totalCount: result.fullSize });
          setHasSearched(true);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
    return Promise.resolve();
  };

  const searchVideos = async () => {
    if (!!activeTab) {
      if (activeTab.name === "Player") {
        return simpleSearch();
      } else {
        return advancedSearch();
      }
    }
  };

  const resetForm = () => {
    setPagination(initialPagination);
    setMetadata({});
    setSelectedTags([]);
    void getMetadataTemplateCall();
  };

  const updateMetadata = (key, value) => {
    setMetadata({ ...metadata, [key]: value });
  };

  const addNewField = () => {
    setFields([...fields, newField]);
  };

  const updateField = (index, field) => {
    const newFields = [...fields];
    newFields[index] = field;
    setFields(newFields);
  };

  const removeField = (index) => {
    const newFields = fields.splice(index, 1);
    setFields(newFields);
  };

  const exportSearchResults = async () => {
    try {
      setLoading(true);
      //todo: get tab type from BCR
      if (activeTab.name === "Player") {
        const tags = !!selectedTags?.length ? selectedTags.map((t) => t.value) : [];
        const metadataSearch = { ...metadata, tags: tags };
        const response = await exportSearchResultsData(metadataSearch, pagination.pageNumber);
        downloadFile(response);
        setHasSearched(true);
      } else {
        const finalFields = getValidFields(fields);
        setFields(finalFields);
        if (finalFields.length) {
          const metadata = createMetadata(finalFields);
          const response = await exportSearchResultsData(metadata);
          downloadFile(response);
          setHasSearched(true);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
    return Promise.resolve();
  };

  useEffect(() => {
    void getMetadataTemplateCall();
  }, []);

  useEffect(() => {
    setSearchResults([]);
    setHasSearched(false);
  }, [activeTab]);

  useEffect(() => {
    //todo: get tab type from BCR
    if (activeTab) {
      if (activeTab.name === "Player") {
        setCanSearch(!!Object.keys(removeNullFields(metadata)).length);
      } else {
        setCanSearch(!!getValidFields(fields).length);
      }
    } else {
      setCanSearch(false);
    }
  }, [activeTab, metadata, fields]);

  useEffect(() => {
    void searchVideos();
  }, [pagination.pageNumber]);

  return (
    <SearchContext.Provider
      value={{
        metadata,
        activeTab,
        setActiveTab,
        searchResults,
        selectedTags,
        setSelectedTags,
        hasSearched,
        fields,
        canSearch,
        pagination,
        updateMetadata,
        search: searchVideos,
        resetForm,
        addNewField,
        updateField,
        removeField,
        exportSearchResults,
        setPagination,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

const useSearch = (): SearchContextType => {
  const context: SearchContextType | undefined = useContext<SearchContextType | undefined>(SearchContext);
  if (context === undefined) {
    throw new Error(`useSearch must be used within a SearchProvider`);
  }
  return context;
};

export { SearchContext, SearchProvider, useSearch };
