import {
  areObjectsEqual,
  DateRange,
  DateRangeInput,
  FormColumn,
  Icon,
  Input,
  randomString,
  Select,
  ValueOpt,
} from "best-common-react";
import React, { useState } from "react";
import styled from "styled-components";
import { useMetadata } from "../../../contexts/MetadataContext";
import { AdvancedSearchOptions } from "../../../types/Metadata";
import {
  AdvancedSearchValue,
  AdvancedSearchValueOptions,
  AdvanceSearchSelectValue,
  SearchField,
} from "../../../types/Search";

const Holder = styled.div.attrs(() => ({
  className: "p-3 mb-2",
}))`
  border: 1px solid ${(props) => props.theme["grey-two"]};
`;

type AdvancedSearchFieldProps = {
  field: SearchField;
  onDelete: () => void;
  onChange: (value: SearchField) => void;
};

const AdvancedSearchField: React.FC<AdvancedSearchFieldProps> = ({ field, onDelete, onChange }) => {
  const {
    tagOptions,
    orgOptions,
    classOptions,
    countryOptions,
    heightOptions,
    advancedSearchOptions,
    positionOptions,
    playerStatusOptions,
    stateOptions,
    schoolTypeOptions,
    throwsOptions,
    batsOptions,
  } = useMetadata();

  const getSelected = (field: SearchField): ValueOpt<AdvancedSearchOptions> | undefined => {
    if (!!field.key) {
      return advancedSearchOptions.find((opt) => opt.value.key === field.key);
    } else {
      return undefined;
    }
  };

  const [selected, setSelected] = useState<ValueOpt<AdvancedSearchOptions> | undefined>(getSelected(field));
  const [id] = useState<string>(randomString());

  const getOptions = (): AdvancedSearchValueOptions[] => {
    switch (selected.value.selectType) {
      case "schoolTypes":
        return schoolTypeOptions;
      case "playerStatus":
        return playerStatusOptions;
      case "position":
        return positionOptions;
      case "state":
      case "schoolState":
        return stateOptions;
      case "bats":
        return batsOptions;
      case "throws":
        return throwsOptions;
      case "height":
        return heightOptions;
      case "country":
        return countryOptions;
      case "source":
      case "org":
        return orgOptions;
      case "tags":
        return tagOptions;
      case "class":
        return classOptions;
      default:
        return [];
    }
  };

  const changedValue = (value: AdvancedSearchValue | AdvancedSearchValueOptions | AdvancedSearchValueOptions[]) => {
    if (selected.value.searchType.name === "dateRange") {
      onChange({ key: selected?.value?.key, value: value as DateRange<any> });
    } else if (selected.value.searchType.name === "text") {
      onChange({ key: selected?.value?.key, value: value as string });
    } else if (selected.value.searchType.name === "select") {
      onChange({ key: selected?.value?.key, value: (value as AdvancedSearchValueOptions).value });
    } else if (selected.value.searchType.name === "multiSelect") {
      const values: AdvanceSearchSelectValue[] = (value as AdvancedSearchValueOptions[]).map((v) => v.value);
      onChange({ key: selected?.value?.key, value: values });
    }
  };

  const changedField = (field: ValueOpt<AdvancedSearchOptions>) => {
    setSelected(field);
    onChange({ key: field?.value?.key, value: "" });
  };

  const getValue = (): AdvancedSearchValue | AdvancedSearchValueOptions | AdvancedSearchValueOptions[] => {
    if (selected.value.searchType.name === "dateRange") {
      const dateRange: DateRange<any> = field.value as DateRange<any>;
      if (!dateRange || (!dateRange.start && !dateRange.end)) {
        return { start: new Date(), end: new Date() };
      }
    }
    if (selected.value.searchType.name === "select") {
      const opts: AdvancedSearchValueOptions[] = getOptions();
      return opts.find((opt: AdvancedSearchValueOptions) => areObjectsEqual(opt.value, field.value));
    }
    if (selected.value.searchType.name === "multiSelect") {
      const opts: AdvancedSearchValueOptions[] = getOptions();
      const values: ValueOpt<AdvanceSearchSelectValue>[] = field.value as ValueOpt<AdvanceSearchSelectValue>[];
      if (!!values?.length) {
        return opts.filter((opt: AdvancedSearchValueOptions) => {
          return !!values.find((value) => {
            //@ts-ignore
            return areObjectsEqual(opt.value, value);
          });
        });
      } else {
        return [];
      }
    }
    return field.value;
  };

  const getInputField = () => {
    switch (selected.value.searchType.name) {
      case "multiSelect":
        return (
          <Select
            id={id}
            value={getValue() as AdvancedSearchValueOptions[]}
            //@ts-ignore
            options={getOptions()}
            //@ts-ignore
            onChange={(value: AdvancedSearchValueOptions[]) => {
              changedValue(value);
            }}
            clearable={true}
            isMulti
          />
        );
      case "select":
        return (
          <Select
            id={id}
            value={getValue() as AdvancedSearchValueOptions}
            //@ts-ignore
            options={getOptions()}
            //@ts-ignore
            onChange={(value: AdvancedSearchValueOptions) => {
              changedValue(value);
            }}
            clearable={true}
          />
        );
      case "dateRange":
        return (
          <DateRangeInput
            id={id}
            value={getValue() as DateRange<any>}
            onChange={(value: DateRange<any>) => {
              changedValue(value);
            }}
            withPortal
          />
        );
      case "text":
      default:
        return (
          <Input
            id={id}
            type="text"
            value={getValue() as string}
            onChange={(value: string) => {
              changedValue(value);
            }}
          />
        );
    }
  };

  return (
    <Holder>
      <div className="row">
        <FormColumn width={2}>
          <Select
            id={`advanced-search-field-${id}`}
            options={advancedSearchOptions}
            value={selected}
            onChange={changedField}
          />
        </FormColumn>
        <FormColumn width={2}>
          <div className="d-flex align-items-center justify-content-end">
            <div style={{ width: "100%" }}>{selected && selected.value && <>{getInputField()}</>}</div>
            <Icon iconName="fa-trash-alt" className="p-1 ms-1" onClick={onDelete} />
          </div>
        </FormColumn>
      </div>
    </Holder>
  );
};

export default AdvancedSearchField;
