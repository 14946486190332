import { Button, Card, FileAttachment, useLoading } from "best-common-react";
import * as Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";
import Protected from "../../components/protected/Protected";
import { MetadataConstants } from "../../constants/MetadataConstants";
import { correctMetadata } from "../../httpClients/ClipsApi";
import { convertMetadata } from "../../utils/MetadataUtils";

const UploadContainer = styled.div.attrs({
  className: "me-2",
})`
  display: flex;
  align-items: start;
  width: 100%;
`;

const removeNulls = (data) => {
  if (data) {
    return data.filter((d) => {
      const keys = Object.keys(d);
      const notNulls = [];
      keys.forEach((key) => {
        if (d[key]) {
          notNulls.push(key);
        }
      });
      return notNulls.length;
    });
  } else {
    return [];
  }
};

const AdminMetadataContainer: React.FC = () => {
  const { loading, setLoading } = useLoading();
  const [csvFile, setCsvFile] = useState([]);
  const [csv, setCsv] = useState([]);
  const [canSave, setCanSave] = useState(false);

  const setData = (file) => {
    if (file.length) {
      setCsvFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        const csvData = removeNulls(Papa.parse(reader.result, { header: true }).data);
        setCsv(convertMetadata(csvData));
      };
      reader.readAsText(file[0]);
    } else {
      setCsvFile([]);
      setCsv([]);
    }
  };

  useEffect(() => {
    setCanSave(!!csvFile && csv.length && !loading);
  }, [csvFile, csv, loading]);

  const upload = async () => {
    try {
      setLoading(true);
      await correctMetadata(csv);
      setCsv([]);
      setCsvFile([]);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <Card.Header>Admin Metadata</Card.Header>
      <Card.Body>
        <div className="mb-3">
          <CSVLink className="me-3" filename="DomesticTemplate" data={MetadataConstants.DOMESTIC} target="_blank">
            Domestic Template
          </CSVLink>
          <CSVLink filename="InternationalTemplate" data={MetadataConstants.INTERNATIONAL} target="_blank">
            International Template
          </CSVLink>
        </div>
        <UploadContainer>
          <div style={{ width: "100%" }}>
            <FileAttachment
              id="admin-metadata-file-attachment"
              displayColumns={["fileName", "size", "fileType"]}
              onChange={setData}
              fileTypes={["text/csv"]}
              files={csvFile}
            />
          </div>
        </UploadContainer>
      </Card.Body>
      <Card.Footer>
        <Button variant="primary" className="ms-2" onClick={upload} disabled={!canSave}>
          Upload
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default Protected(AdminMetadataContainer);
