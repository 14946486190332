import { Card, urlReplace, useLoading } from "best-common-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MetadataEditBody from "../components/metadata/edit/MetadataEditBody";
import MetadataEditFooter from "../components/metadata/edit/MetadataEditFooter";
import Protected from "../components/protected/Protected";
import { RouteConstants } from "../constants/RouteConstants";
import { getVideo, updateMetadata } from "../httpClients/ClipsApi";
import { BoxFileDTO } from "../types/Video";

const MetadataEditContainer: React.FC = () => {
  const { fileId } = useParams();
  const navigate = useNavigate();

  const { setLoading } = useLoading();
  const [video, setVideo] = useState<BoxFileDTO | undefined>(undefined);
  //todo: validate metadata - write in util
  const [validToSave] = useState<boolean>(true);

  const setMetadata = (metadata) => {
    setVideo({ ...video, metadata: metadata });
  };

  const save = async () => {
    try {
      if (!!video) {
        setLoading(true);
        await updateMetadata(video.fileId, video.metadata);
        navigate(urlReplace(RouteConstants.VIDEO, { fileId }));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchVideo = async () => {
    if (!!fileId) {
      try {
        setLoading(true);
        const result: BoxFileDTO = await getVideo(parseInt(fileId));
        setVideo(result);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    void fetchVideo();
  }, [fileId]);

  return (
    <Card>
      <Card.Header>Metadata Edit</Card.Header>
      <Card.Body>{!!video?.metadata && <MetadataEditBody video={video} setMetadata={setMetadata} />}</Card.Body>
      <Card.Footer>
        {!!video && <MetadataEditFooter videoId={video.fileId} save={save} validToSave={validToSave} />}
      </Card.Footer>
    </Card>
  );
};

export default Protected(MetadataEditContainer);
