import React from "react";
import { useSearch } from "../../contexts/SearchContext";
import AdvancedSearchFooter from "./advancedSearch/AdvancedSearchFooter";
import PlayerSearchFooter from "./playerSearch/PlayerSearchFooter";

const SearchFooter = () => {
  const { activeTab } = useSearch();
  const isPlayerSearchTab = activeTab?.name === "Player";
  return <div>{isPlayerSearchTab ? <PlayerSearchFooter /> : <AdvancedSearchFooter />}</div>;
};

export default SearchFooter;
