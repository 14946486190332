import { Button, StickyFooterButtons, useLoading } from "best-common-react";
import React from "react";
import { useSearch } from "../../../contexts/SearchContext";

const AdvancedSearchFooter = () => {
  const { loading } = useLoading();
  const { search, searchResults, exportSearchResults } = useSearch();
  return (
    <StickyFooterButtons>
      {!!searchResults?.length && (
        <Button variant="default" onClick={exportSearchResults} disabled={loading}>
          Export Results
        </Button>
      )}
      <Button variant="primary" onClick={search}>
        Search
      </Button>
    </StickyFooterButtons>
  );
};

export default AdvancedSearchFooter;
