import { DataTable, DataTableColumn, DateTimeFormatter } from "best-common-react";
import React from "react";
import { useFolders } from "../../contexts/FoldersContext";
import { SelectableFolder } from "../../types/Folders";
import BoxLinkFormatter from "./BoxLinkFormatter";

const Columns: DataTableColumn<SelectableFolder>[] = [
  {
    name: " ",
    key: "boxSharedLink",
    readonlyFormatter: BoxLinkFormatter,
    width: 50,
  },
  {
    name: "File Name",
    key: "fileName",
  },
  {
    name: "Upload Date",
    key: "uploadDate",
    readonlyFormatter: DateTimeFormatter,
  },
];

const UploadFolders = () => {
  const { items } = useFolders();
  return (
    <div className="p-2">
      <DataTable data={items} columns={Columns} />
    </div>
  );
};

export default UploadFolders;
