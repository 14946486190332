import { formatDate, Tab, useLoading } from "best-common-react";
import React, { useContext, useEffect, useState } from "react";
import { getDownloads, getViews } from "../../httpClients/ClipsApi";
import { AdminSearchFilters } from "../../types/Admin";
import { ReportData } from "../../types/Reports";
import { tomorrow, yesterday } from "../../utils/DateUtils";

type AdminReportingContextType = {
  activeTab: Tab;
  setActiveTab: (value: Tab) => void;
  filters: AdminSearchFilters;
  setFilters: (value: AdminSearchFilters) => void;
  data: ReportData[];
  isValidSearch: boolean;
  setIsValidSearch: (value: boolean) => void;
  changeFilters: (key: string, value: any) => any;
  search: () => Promise<any>;
};

const AdminReportingContext = React.createContext<AdminReportingContextType | undefined>(undefined);

const AdminReportingProvider = ({ children }) => {
  const { setLoading } = useLoading();
  const [activeTab, setActiveTab] = useState<Tab | undefined>(undefined);
  const [isValidSearch, setIsValidSearch] = useState(false);
  const [filters, setFilters] = useState<AdminSearchFilters>({
    dateRange: { start: yesterday, end: tomorrow },
    org: undefined,
  });
  const [data, setData] = useState<ReportData[]>([]);

  const getUserViews = async () => {
    try {
      setLoading(true);
      const result = await getViews(
        filters.org.name,
        formatDate(filters.dateRange.start),
        formatDate(filters.dateRange.end),
      );
      setData(result);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getUserDownloads = async () => {
    try {
      setLoading(true);
      const result = await getDownloads(
        filters.org.name,
        formatDate(filters.dateRange.start),
        formatDate(filters.dateRange.end),
      );
      setData(result);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const search = async () => {
    if (isValidSearch) {
      if (activeTab?.name === "Views") {
        await getUserViews();
      } else if (activeTab?.name === "Downloads") {
        await getUserDownloads();
      }
    }
  };

  const changeFilters = (key: string, value: any) => {
    const newValue = { ...filters, [key]: value };
    setFilters(newValue);
  };

  useEffect(() => {
    setIsValidSearch(!!filters.org && !!filters.dateRange.start && !!filters.dateRange.end);
  }, [filters]);

  useEffect(() => {
    setData([]);
  }, [activeTab]);

  return (
    <AdminReportingContext.Provider
      value={{
        activeTab,
        setActiveTab,
        filters,
        setFilters,
        data,
        isValidSearch,
        setIsValidSearch,
        changeFilters,
        search,
      }}
    >
      {children}
    </AdminReportingContext.Provider>
  );
};

const useAdminReporting = () => {
  const context: AdminReportingContextType | undefined = useContext<AdminReportingContextType | undefined>(
    AdminReportingContext,
  );
  if (context === undefined) {
    throw new Error(`useAdminReporting must be used within a AdminReportingProvider`);
  }
  return context;
};

export { AdminReportingProvider, useAdminReporting };
