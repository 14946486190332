import React from "react";
import { Navigate } from "react-router-dom";
import { RouteConstants } from "../../constants/RouteConstants";
import { useAuth } from "../../contexts/AuthContext";

export default (WrappedComponent) => {
  const ProtectedComponent = ({ ...rest }) => {
    const { loggedIn } = useAuth();
    if (loggedIn) {
      return <WrappedComponent {...rest} />;
    } else {
      return <Navigate to={RouteConstants.LOGIN} replace={true} />;
    }
  };

  return ProtectedComponent;
};
