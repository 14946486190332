import { FormColumn, Select, ValueOpt } from "best-common-react";
import React from "react";
import { useMetadata } from "../../contexts/MetadataContext";

type ProfileSearchFiltersProps = {
  source?: string;
  setSource: (source: string) => void;
  selectedTags: string[];
  setSelectedTags: (tags: string[]) => void;
};

const ProfileSearchFilters: React.FC<ProfileSearchFiltersProps> = ({
  source,
  setSource,
  selectedTags,
  setSelectedTags,
}) => {
  const { convertToLabelValue, tagOptions, orgOptions } = useMetadata();

  const sourceOnChange = (option: ValueOpt<any> | null) => {
    setSource(!!option ? option.value : null);
  };

  const tagsOnChange = (options: ValueOpt<any>[] | null) => {
    setSelectedTags(!!options ? options.map(({ value }) => value) : []);
  };

  return (
    <div className="row">
      <FormColumn width={2}>
        <div className="form-group">
          <Select
            id="source-select"
            label="Source"
            options={orgOptions}
            value={convertToLabelValue(source)}
            onChange={sourceOnChange}
            clearable={true}
          />
        </div>
      </FormColumn>
      <FormColumn width={2}>
        <div className="form-group">
          <Select
            id="tags-select"
            label="tags"
            isMulti
            onChange={tagsOnChange}
            options={tagOptions}
            value={selectedTags.map((tag) => ({ label: tag, value: tag }))}
            clearable={true}
          />
        </div>
      </FormColumn>
    </div>
  );
};

export default ProfileSearchFilters;
