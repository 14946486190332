import { Typography } from "best-common-react";
import React from "react";
import { BoxFileDTO, ClipsMetadataDTO } from "../../../types/Video";
import MetadataForm from "../MetadataForm";

type MetadataEditBodyProps = {
  video: BoxFileDTO;
  setMetadata: (metadata: ClipsMetadataDTO) => void;
};

const MetadataEditBody: React.FC<MetadataEditBodyProps> = ({ video, setMetadata }) => (
  <div>
    <Typography variant="h5">{video.fileName}</Typography>
    <MetadataForm metadata={video.metadata} onChange={setMetadata} />
  </div>
);

export default MetadataEditBody;
