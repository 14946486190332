import { FormatterType, Icon } from "best-common-react";
import React from "react";
import { SelectableFolder } from "../../types/Folders";

const BoxLinkFormatter: React.FC<FormatterType<SelectableFolder>> = ({ value }) => (
  <a href={value.url} target="_blank">
    <Icon iconName="fa-external-link-alt" />
  </a>
);

export default BoxLinkFormatter;
