import { DateRangeInput, FormColumn, Select } from "best-common-react";
import React from "react";
import { useAdminReporting } from "../../../contexts/ admin/AdminReportingContext";
import { useMetadata } from "../../../contexts/MetadataContext";

const AdminReportingForm = () => {
  const { filters, changeFilters, search } = useAdminReporting();
  const { orgOptions, getValueFromOptions } = useMetadata();

  return (
    <div className="mb-3">
      <form
        onSubmit={(event) => {
          event.preventDefault();
          void search();
        }}
      >
        <div className="row">
          <FormColumn width={2}>
            <DateRangeInput
              id="dateRange"
              label="Date Range"
              value={filters.dateRange}
              onChange={(value) => changeFilters("dateRange", value)}
              withPortal
              maxRange={{
                months: 1,
              }}
            />
          </FormColumn>
          <FormColumn width={2}>
            <Select
              id="club"
              label="Club"
              options={orgOptions}
              value={getValueFromOptions(orgOptions, filters.org)}
              onChange={(event) => changeFilters("org", event.value)}
            />
          </FormColumn>
        </div>
      </form>
    </div>
  );
};

export default AdminReportingForm;
