import { Button, Modal, Select, useLoading, ValueOpt } from "best-common-react";
import React, { useEffect, useState } from "react";
import { useMetadata } from "../../contexts/MetadataContext";
import { updateTagsForVideo } from "../../httpClients/ClipsApi";
import { Tags } from "../../types/Tags";

const getTagObjects = (newTags: ValueOpt<Tags>[], tags: Tags[]): Tags[] => {
  const toBeSaved: Tags[] = [];

  newTags.forEach((nt) => {
    const t: Tags | undefined = tags.find((t: Tags) => t.tag.toLowerCase() === nt.value.tag.toLowerCase());
    if (!!t) {
      toBeSaved.push(t);
    }
  });

  return toBeSaved;
};

type EditTagsModalProps = {
  existingTags: string[];
  videoId: string;
  setVideoTags: (value: string[]) => void;
  open: boolean;
  close: () => void;
};

const EditTagsModal: React.FC<EditTagsModalProps> = ({ existingTags, videoId, setVideoTags, open, close }) => {
  const { tagOptions, tags } = useMetadata();
  const { setLoading } = useLoading();
  const [newTagOptions, setNewTagOptions] = useState<ValueOpt<Tags>[]>([]);
  //todo: check if can update tags
  const [canUpdateTags] = useState<boolean>(true);

  const updateTags = async () => {
    try {
      setLoading(true);
      const toBeSaved: Tags[] = getTagObjects(newTagOptions, tags);
      await updateTagsForVideo(videoId, toBeSaved);
      setVideoTags(toBeSaved.map((tag: Tags) => tag.tag));
      close();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open && !!existingTags && !!tagOptions) {
      setNewTagOptions(tagOptions.filter((value: ValueOpt<Tags>) => existingTags.includes(value.value.tag)));
    }
  }, [open, existingTags, tagOptions]);

  return (
    <Modal show={open}>
      <Modal.Header
        close={() => {
          close();
        }}
      >
        Edit Tags
      </Modal.Header>
      <Modal.Body>
        <div>
          <Select
            id="tags"
            label="Tags"
            isMulti
            onChange={(value: ValueOpt<Tags>[]) => {
              setNewTagOptions(value);
            }}
            options={tagOptions}
            value={newTagOptions}
            clearable={true}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button variant="primary" onClick={updateTags} disabled={!canUpdateTags} className="me-2">
            Save
          </Button>
          <Button
            variant="default"
            onClick={(event: any) => {
              event.stopPropagation();
              close();
            }}
          >
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditTagsModal;
