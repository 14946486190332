import { FormColumn, Input, Select, ValueOpt } from "best-common-react";
import React from "react";
import { useMetadata } from "../../../contexts/MetadataContext";
import { useSearch } from "../../../contexts/SearchContext";
import { Org } from "../../../types/Metadata";

const PlayerSearchForm = () => {
  const { search, selectedTags, setSelectedTags, metadata, updateMetadata } = useSearch();
  const { tagOptions, orgOptions, positionOptions, playerStatusOptions, convertToLabelValue } = useMetadata();
  return (
    <div>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          search();
        }}
      >
        <div className="row">
          <FormColumn width={2}>
            <Input
              id="firstName"
              label="First Name"
              value={metadata.firstName ? metadata.firstName : ""}
              onChange={(value: string) => {
                updateMetadata("firstName", value);
              }}
              clearable
              gutterBottom
            />
          </FormColumn>
          <FormColumn width={2}>
            <Input
              id="lastName"
              label="Last Name"
              value={metadata.lastName ? metadata.lastName : ""}
              onChange={(value: string) => {
                updateMetadata("lastName", value);
              }}
              clearable
              gutterBottom
            />
          </FormColumn>
          <FormColumn width={2}>
            <Select
              id="position"
              label="Position"
              options={positionOptions}
              value={convertToLabelValue(metadata.position)}
              onChange={(event) => updateMetadata("position", event ? event.value : null)}
              clearable={true}
              gutterBottom
            />
          </FormColumn>
          <FormColumn width={2}>
            <Select
              id="playerStatus"
              label="Player Status"
              options={playerStatusOptions}
              value={convertToLabelValue(metadata.playerStatus)}
              onChange={(event) => updateMetadata("playerStatus", event ? event.value : null)}
              clearable={true}
              gutterBottom
            />
          </FormColumn>
          <FormColumn width={2}>
            <div className="form-group">
              <Select
                id="videoSource"
                label="Video Source"
                options={orgOptions}
                value={convertToLabelValue(metadata.source)}
                onChange={(event: ValueOpt<Org>) => updateMetadata("source", event ? event.value : null)}
                clearable={true}
                gutterBottom
              />
            </div>
          </FormColumn>
          <FormColumn width={2}>
            <Select
              id="tags"
              label="Tags"
              isMulti
              onChange={setSelectedTags}
              options={tagOptions}
              value={selectedTags}
              clearable={true}
              gutterBottom
            />
          </FormColumn>
        </div>
        <div>
          <button type="submit" hidden={true} />
        </div>
      </form>
    </div>
  );
};

export default PlayerSearchForm;
