import { SearchResult } from "../types/Search";
import { BoxFileDTO } from "../types/Video";

export const buildGroupKey = (data: SearchResult): SearchResult => {
  let groupKey;
  if (data.metadata.ebis) {
    groupKey = data.metadata.ebis;
  } else if (data.metadata.firstName && data.metadata.lastName) {
    if (data.metadata.birthDate) {
      groupKey = data.metadata.firstName.trim() + data.metadata.lastName.trim() + data.metadata.birthDate;
    } else {
      groupKey = data.metadata.firstName.trim() + data.metadata.lastName.trim();
    }
  } else if (data.metadata.firstName) {
    if (data.metadata.birthDate) {
      groupKey = data.metadata.firstName.trim() + data.metadata.birthDate;
    } else {
      groupKey = data.metadata.firstName.trim();
    }
  } else if (data.metadata.lastName) {
    if (data.metadata.birthDate) {
      groupKey = data.metadata.lastName.trim() + data.metadata.birthDate;
    } else {
      groupKey = data.metadata.lastName.trim();
    }
  } else if (data.metadata.birthDate) {
    groupKey = data.metadata.birthDate;
  }
  data.groupKey = groupKey;
  return data;
};

export const convertSearchResult = (sr: BoxFileDTO): SearchResult => ({
  fileId: sr.fileId,
  playerName: sr.metadata.firstName + " " + sr.metadata.lastName,
  school: sr.metadata.school,
  city: sr.metadata.city,
  state: sr.metadata.state,
  class: sr.metadata.classification,
  playerStatus: sr.metadata.playerStatus,
  ebis: sr.metadata.ebis,
  fileName: sr.fileName,
  filmDate: sr.metadata.filmDate,
  source: sr.metadata.source,
  tags: sr.tags,
  metadata: sr.metadata,
  groupKey: "",
});
