import { AttributeHeader, AttributeValue, formatDate, urlReplace } from "best-common-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { RouteConstants } from "../../constants/RouteConstants";
import { BoxFileDTO } from "../../types/Video";

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border: 1px solid ${(props) => props.theme["grey-two"]};
  cursor: pointer;
`;

type ProfileSearchResultProps = {
  files: BoxFileDTO[];
};

const ProfileSearchResults: React.FC<ProfileSearchResultProps> = ({ files }) => {
  const navigate = useNavigate();

  return (
    <div>
      {files.map((file) => (
        <Card key={file.fileId} onClick={() => navigate(urlReplace(RouteConstants.VIDEO, { videoId: file.fileId }))}>
          <div>
            <AttributeHeader>File Name</AttributeHeader>
            <AttributeValue>{file.metadata.fileName}</AttributeValue>
          </div>
          <div>
            <AttributeHeader>School</AttributeHeader>
            <AttributeValue>{file.metadata.school}</AttributeValue>
          </div>
          <div>
            <AttributeHeader>City</AttributeHeader>
            <AttributeValue>{file.metadata.city}</AttributeValue>
          </div>
          <div>
            <AttributeHeader>State</AttributeHeader>
            <AttributeValue>{file.metadata.state}</AttributeValue>
          </div>
          <div>
            <AttributeHeader>Class</AttributeHeader>
            <AttributeValue>{file.metadata.classification}</AttributeValue>
          </div>
          <div>
            <AttributeHeader>Player Status</AttributeHeader>
            <AttributeValue>{file.metadata.playerStatus}</AttributeValue>
          </div>
          <div>
            <AttributeHeader>Film Date</AttributeHeader>
            <AttributeValue>{formatDate(file.metadata.filmDate)}</AttributeValue>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default ProfileSearchResults;
