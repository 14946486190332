import { AlertConstants, getLocalStorageItem, useAlerts } from "best-common-react";
import React, { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { ClipsAlert } from "../types/Alerts";
import ClipsClient from "./ClipsApiClient";

function createErrorMessage(error) {
  const status = error.response ? error.response.status : -1;
  switch (status) {
    case 401:
      return "Your session has expired.";
    case 403:
      return "You do not have the correct permission to do this action.";
    case 404:
      return "Resource not found.";
    case 500:
      return "Internal server error";
    default:
      return "Clips error please contact support.";
  }
}

function isNotOpenUrl(url) {
  return url.indexOf("/oauth/token") < 0 || url.indexOf("statsapi") < 0;
}

const HttpInterceptor = ({ children }) => {
  const { logout } = useAuth();
  const { addAlert } = useAlerts();

  const createResponseAlerts = (alerts: ClipsAlert[]) => {
    alerts.forEach((alert: ClipsAlert) => {
      addAlert({
        type: !!alert.severity ? alert.severity : AlertConstants.TYPES.WARNING,
        text: !!alert.message ? alert.message : "There was an error please contact support",
      });
    });
  };

  const createInterceptors = () => {
    ClipsClient.interceptors.request.use(
      (config) => {
        // Do something before request is sent
        if (isNotOpenUrl(config.url) || config.headers.addToken) {
          const { accessToken } = getLocalStorageItem("okta-token-storage");
          config.headers.Authorization = `Bearer ${accessToken.accessToken}`;
        }
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      },
    );

    ClipsClient.interceptors.response.use(
      (response) => {
        if (response && response.data && response.data.alerts) {
          createResponseAlerts(response.data.alerts);
        }
        return response;
      },
      (error) => {
        if (error) {
          if (error.response && error.response.data && "invalid_token" === error.response.data.error) {
            console.error("invalid token");
          } else if (error.response && error.response.data && "invalid_grant" === error.response.data.error) {
            console.error("Invalid grant");
          } else if (error.response && error.response.status === 401) {
            logout();
          } else {
            if (error.response.data && error.response.data.alerts) {
              createResponseAlerts(error.response.data.alerts);
            } else {
              const message = createErrorMessage(error);
              addAlert({
                type: AlertConstants.TYPES.WARNING,
                text: message,
              });
            }
          }
          return Promise.reject(error);
        } else {
          return Promise.reject();
        }
      },
    );
  };

  useEffect(() => {
    createInterceptors();
  }, []);

  return <>{children}</>;
};

export default HttpInterceptor;
