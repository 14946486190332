import { Button, StickyFooterButtons, useLoading } from "best-common-react";
import React from "react";
import { useSearch } from "../../../contexts/SearchContext";

const PlayerSearchFooter = () => {
  const { loading } = useLoading();
  const { search, resetForm, canSearch, exportSearchResults, searchResults } = useSearch();
  return (
    <StickyFooterButtons>
      {!!searchResults?.length && (
        <Button variant="default" onClick={exportSearchResults} disabled={loading || !canSearch}>
          Export Results
        </Button>
      )}
      <Button variant="default" onClick={resetForm}>
        Reset Form
      </Button>
      <Button variant="primary" onClick={search} disabled={loading || !canSearch}>
        Search
      </Button>
    </StickyFooterButtons>
  );
};

export default PlayerSearchFooter;
