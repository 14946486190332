import React from "react";
import {
  BigPlayButton,
  ControlBar,
  LoadingSpinner,
  PlaybackRateMenuButton,
  Player,
  PlayerActions,
  PlayerState,
  Shortcut,
  ShortcutItem,
} from "video-react";
import { frameBack, frameForward } from "../../../utils/VideoUtils";
import FrameByFrame from "./FrameByFrame";

const Video = ({ url }) => {
  const keyboardShortcuts: ShortcutItem[] = [
    {
      keyCode: 37, // left arrow
      handle: (_player: PlayerState, actions: PlayerActions): void => {
        frameBack(actions);
      },
      ctrl: false,
    },
    {
      keyCode: 39, // right arrow
      handle: (_player: PlayerState, actions: PlayerActions): void => {
        frameForward(actions);
      },
      ctrl: false,
    },
  ];

  return (
    <Player autoPlay src={url} playsInline={false}>
      <Shortcut clickable={false} shortcuts={keyboardShortcuts} />
      <BigPlayButton position="center" />
      <LoadingSpinner />
      <ControlBar autoHide={true}>
        <FrameByFrame />
        <PlaybackRateMenuButton rates={[1, 0.75, 0.5, 0.25, 0.1]} />
      </ControlBar>
    </Player>
  );
};

export default Video;
