import { useOktaAuth } from "@okta/okta-react";
import { Button, Icon, Traditional, Typography } from "best-common-react";
import React from "react";
import styled from "styled-components";
import bg from "../../assets/logo/bg.png";
import { RouteConstants } from "../../constants/RouteConstants";

const LoginBG = styled.div.attrs({})`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-image: url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
`;

const LoginContainer = styled.div.attrs({
  className: "d-flex justify-content-center align-items-center",
})`
  height: calc(100vh - 83px);
`;

const LoginHolder = styled.div`
  width: 500px;
  border-radius: 8px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.5);
  background-color: #f2f3f4;
  border: solid 1.25rem #ffffff;
  z-index: 2;
`;

const LoginFormHolder = styled.div.attrs({
  className: "d-flex justify-content-center align-items-center flex-column p-3",
})``;

const Login = () => {
  const { oktaAuth } = useOktaAuth();
  const loginFunc = () => {
    void oktaAuth.signInWithRedirect({ originalUri: RouteConstants.BASE });
  };

  return (
    <div>
      <LoginBG />
      <LoginContainer>
        <LoginHolder>
          <LoginFormHolder>
            <Typography variant="h2">Clips 2.5</Typography>
            <div className="mb-4">
              <Typography variant="h5">
                Please use your Clips credentials, if you are prompted, when redirected by clicking the button below.
              </Typography>
            </div>
            <Button variant="primary" onClick={loginFunc} className="mb-3">
              Sign In With MLB SSO <Icon styles={{ color: Traditional["white"] }} iconName="fa-key" />
            </Button>
          </LoginFormHolder>
        </LoginHolder>
      </LoginContainer>
    </div>
  );
};

export default Login;
