import { Card } from "best-common-react";
import React from "react";
import Protected from "../components/protected/Protected";
import SearchBody from "../components/search/SearchBody";
import SearchFooter from "../components/search/SearchFooter";
import { SearchProvider } from "../contexts/SearchContext";

const SearchContainer: React.FC = () => {
  return (
    <SearchProvider>
      <Card>
        <Card.Header>Search</Card.Header>
        <Card.Body>
          <SearchBody />
        </Card.Body>
        <Card.Footer>
          <SearchFooter />
        </Card.Footer>
      </Card>
    </SearchProvider>
  );
};

export default Protected(SearchContainer);
