import { OktaAuth } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { useLoading } from "best-common-react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { OKTA_CONFIG } from "../config";
import { getUserInfo, toggleDailyEmail } from "../httpClients/ClipsApi";
import { Authority, ClipsFolders, UserInfo } from "../types/User";

type AuthContextType = {
  loggedIn: boolean;
  userInfo?: UserInfo;
  isProd: boolean;
  isAdmin: boolean;
  isBocAdmin: boolean;
  clipsFolders?: ClipsFolders;
  pathname: string;
  isSubscribed: boolean;
  toggleSubscribed: () => Promise<any>;
  getUserName: () => string;
  teamLogo: string;
  login: () => Promise<any>;
  logout: () => void;
};

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

const { ENV } = window.MLBBest.envVariables || {};

const isProd: boolean = !!ENV && ENV.toUpperCase() === "PROD";

const oktaAuth: OktaAuth = new OktaAuth(OKTA_CONFIG.oidc);

const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const { setLoading } = useLoading();
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isBocAdmin, setIsBocAdmin] = useState<boolean>(false);
  const [clipsFolders, setClipsFolders] = useState<ClipsFolders | undefined>(undefined);
  const [pathname, setPathname] = useState<string>("");
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const { authState } = useOktaAuth();

  const login = async () => {
    try {
      const result: UserInfo = await getUserInfo();
      setUserInfo(result);
      checkForAdmin(result);
      setLoggedIn(true);
      setClipsFolders(result.clipsFolders);
      setIsSubscribed(result.emailOptIn);
    } catch (e) {
      console.debug("not logged in");
    }
  };

  const logout = (): void => {
    localStorage.clear();
    setLoggedIn(false);
    setUserInfo(undefined);
    setClipsFolders(undefined);
  };

  const checkForAdmin = (data: UserInfo) => {
    if (data.authorities) {
      const clipsPerm: Authority | undefined = data.authorities.find((a) => a.authority.includes("CLIPS-"));
      setIsAdmin(!!clipsPerm && clipsPerm.authority.includes("ADMIN"));
      setIsBocAdmin(!!clipsPerm && clipsPerm.authority.includes("MLSB-ADMIN"));
    } else {
      setIsAdmin(false);
      setIsBocAdmin(false);
    }
  };

  const teamLogo = useMemo((): string => {
    return !!userInfo?.org?.teamId
      ? `https://www.mlbstatic.com/team-logos/team-primary-on-dark/${userInfo?.org?.teamId}.svg`
      : "https://www.mlbstatic.com/team-logos/league-on-dark/1.svg";
  }, [userInfo]);

  const getUserName = (): string => {
    return !!userInfo?.email ? userInfo.email : "";
  };

  const toggleSubscribed = async () => {
    try {
      if (!!userInfo?.email) {
        setLoading(true);
        const result = await toggleDailyEmail(userInfo.email);
        setIsSubscribed(result.subscribedFlag);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPathname(location.pathname);
  }, []);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      // when the user becomes authenticated, call onLogin() to populate AuthContext's user info
      login();
    }
  }, [authState]);

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        userInfo,
        isProd,
        isAdmin,
        isBocAdmin,
        clipsFolders,
        pathname,
        isSubscribed,
        toggleSubscribed,
        getUserName,
        teamLogo,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextType => {
  const context: AuthContextType | undefined = useContext<AuthContextType | undefined>(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuth, oktaAuth };
