import { DateInput, FormColumn, Input, Select, Textarea, Typography, ValueOpt } from "best-common-react";
import React from "react";
import { useMetadata } from "../../contexts/MetadataContext";
import {
  Bats,
  Classes,
  Countries,
  Heights,
  Org,
  PlayerStatus,
  Position,
  SchoolType,
  States,
  Throws,
} from "../../types/Metadata";
import { Tags } from "../../types/Tags";
import { ClipsMetadataDTO } from "../../types/Video";

type MetadataFormProps = {
  metadata: ClipsMetadataDTO;
  onChange: (value: ClipsMetadataDTO) => void;
};

const MetadataForm: React.FC<MetadataFormProps> = ({ metadata, onChange }) => {
  const {
    tagOptions,
    orgOptions,
    classOptions,
    countryOptions,
    heightOptions,
    positionOptions,
    playerStatusOptions,
    stateOptions,
    schoolTypeOptions,
    throwsOptions,
    batsOptions,
  } = useMetadata();
  const isAmateur = metadata?.playerStatus === "AM";
  const isPro = metadata?.playerStatus === "PRO";
  const isIntl = metadata?.playerStatus === "INTL";
  const isUsaOrCan = metadata?.country === "United States" || metadata?.country === "Canada";
  const minDate = new Date("1/1/1980");

  return (
    <form>
      <Typography variant="h5">Player Details</Typography>
      <div className="row">
        <FormColumn width={3}>
          <Select
            id="playerStatus"
            required
            label="Player Status"
            placeholder="Player Status"
            options={playerStatusOptions}
            value={playerStatusOptions.find((opt: ValueOpt<PlayerStatus>) => opt.value === metadata.playerStatus)}
            onChange={(value: ValueOpt<PlayerStatus>) =>
              onChange({ ...metadata, playerStatus: value ? value.value : "" })
            }
            clearable={true}
          />
        </FormColumn>
        <FormColumn width={3}>
          <Input
            id="lastName"
            label="Last Name"
            placeholder="Last Name"
            required
            value={metadata.lastName}
            onChange={(value) => onChange({ ...metadata, lastName: value })}
          />
        </FormColumn>
        <FormColumn width={3}>
          <Input
            id="firstName"
            label="First Name"
            placeholder="First Name"
            required
            value={metadata.firstName}
            onChange={(value) => onChange({ ...metadata, firstName: value })}
          />
        </FormColumn>
        <FormColumn width={3}>
          <Input
            id="rosterName"
            label="Roster Name"
            placeholder="Roster Name"
            value={metadata.rosterName}
            onChange={(value) => onChange({ ...metadata, rosterName: value })}
          />
        </FormColumn>
        <FormColumn width={3}>
          <Input
            id="middleName"
            label="Middle Name"
            placeholder="Middle Name"
            value={metadata.middleName}
            onChange={(value) => onChange({ ...metadata, middleName: value })}
          />
        </FormColumn>
        {isIntl && (
          <FormColumn width={3}>
            <Input
              id="extendedLastName"
              label="Extended Last Name"
              placeholder="Extended Last Name"
              value={metadata.extendedLastName}
              onChange={(value) => onChange({ ...metadata, extendedLastName: value })}
            />
          </FormColumn>
        )}
        <FormColumn width={3}>
          <Input
            id="playerId"
            label="Player ID"
            placeholder="Player ID"
            value={metadata.playerId}
            onChange={(value) => onChange({ ...metadata, playerId: value })}
          />
        </FormColumn>
        <FormColumn width={3}>
          <Input
            id="ebisId"
            label="EBIS ID"
            placeholder="EBIS ID"
            value={metadata.ebis}
            onChange={(value) => onChange({ ...metadata, ebis: value })}
          />
        </FormColumn>
        <FormColumn width={3}>
          <Input
            id="dplId"
            label="DPL ID"
            placeholder="DPL ID"
            value={metadata.dplId}
            onChange={(value) => onChange({ ...metadata, dplId: value })}
          />
        </FormColumn>
        {isAmateur && (
          <>
            <FormColumn width={3}>
              <Select
                id="class"
                label="Class"
                placeholder="Class"
                options={classOptions}
                value={classOptions.find((opt: ValueOpt<Classes>) => opt.value.name === metadata.classification)}
                onChange={(value: ValueOpt<Classes> | undefined) =>
                  onChange({ ...metadata, classification: value ? value.value.name : "" })
                }
                clearable={true}
              />
            </FormColumn>
            <FormColumn width={3}>
              <Input
                id="school"
                label="School"
                placeholder="School"
                value={metadata.school}
                onChange={(value) => onChange({ ...metadata, school: value })}
              />
            </FormColumn>
            <FormColumn width={3}>
              <Select
                id="schoolType"
                label="School Type"
                placeholder="School Type"
                options={schoolTypeOptions}
                value={schoolTypeOptions.find((opt: ValueOpt<SchoolType>) => opt.value.key === metadata.schoolType)}
                onChange={(value: ValueOpt<SchoolType>) =>
                  onChange({ ...metadata, schoolType: value ? value.value.key : "" })
                }
                clearable={true}
              />
            </FormColumn>
          </>
        )}
        <FormColumn width={3}>
          <Input
            id="schoolCity"
            label={isAmateur ? "School City" : "City"}
            placeholder="School City"
            value={metadata.city}
            onChange={(value) => onChange({ ...metadata, city: value })}
          />
        </FormColumn>
        <FormColumn width={3}>
          {!metadata?.country?.length || isUsaOrCan ? (
            <Select
              id="schoolStateSelect"
              label="School State/Providence"
              placeholder="School State/Providence"
              options={stateOptions}
              value={stateOptions.find((opt: ValueOpt<States>) => opt.value.key === metadata.state)}
              onChange={(value: ValueOpt<States> | undefined) =>
                onChange({ ...metadata, state: value ? value.value.key : "" })
              }
              clearable={true}
            />
          ) : (
            <Input
              id="schoolStateInput"
              label="School State/Providence"
              placeholder="School State/Providence"
              value={metadata.city}
              onChange={(value) => onChange({ ...metadata, state: value })}
            />
          )}
        </FormColumn>
        {!isPro && (
          <FormColumn width={3}>
            <Select
              id="country"
              label="Country"
              placeholder="Country"
              options={countryOptions}
              value={countryOptions.find((opt: ValueOpt<Countries>) => opt.value.name === metadata.country)}
              onChange={(value: ValueOpt<Countries> | undefined) =>
                onChange({ ...metadata, country: value ? value.value.name : "" })
              }
              clearable={true}
            />
          </FormColumn>
        )}
        {isIntl && (
          <FormColumn width={3}>
            <Input
              id="trainerName"
              label="Trainer Name"
              placeholder="Trainer Name"
              value={metadata.trainerName}
              onChange={(value) => onChange({ ...metadata, trainerName: value })}
            />
          </FormColumn>
        )}
        <FormColumn width={3}>
          <Input
            id="eligibility"
            label="Eligibility"
            placeholder="Eligibility"
            value={metadata.eligibility}
            onChange={(value) => onChange({ ...metadata, eligibility: value })}
          />
        </FormColumn>
        <FormColumn width={3}>
          <Input
            id="ofp"
            label="OFP"
            placeholder="OFP"
            value={metadata.ofp}
            onChange={(value) => onChange({ ...metadata, ofp: value })}
          />
        </FormColumn>
        {isPro && (
          <>
            <FormColumn width={3}>
              <Select
                id="organization"
                label="Organization"
                placeholder="Organization"
                options={orgOptions}
                value={orgOptions.find((opt: ValueOpt<Org>) => opt.value.name === metadata.org)}
                onChange={(value: ValueOpt<Org> | undefined) => {
                  onChange({ ...metadata, org: !!value ? value.value.name : "" });
                }}
                clearable={true}
              />
            </FormColumn>
            <FormColumn width={3}>
              <Input
                id="team"
                label="Team"
                placeholder="Team"
                value={metadata.team}
                onChange={(value) => onChange({ ...metadata, team: value })}
              />
            </FormColumn>
            <FormColumn width={3}>
              <Input
                id="league"
                label="League"
                placeholder="League"
                value={metadata.league}
                onChange={(value) => onChange({ ...metadata, league: value })}
              />
            </FormColumn>
          </>
        )}
        <FormColumn width={3}>
          <Select
            id="position"
            label="Position"
            placeholder="Position"
            options={positionOptions}
            value={positionOptions.find((opt: ValueOpt<Position>) => opt.value.key === metadata.position)}
            onChange={(value: ValueOpt<Position> | undefined) =>
              onChange({ ...metadata, position: value ? value.value.key : "" })
            }
            clearable={true}
          />
        </FormColumn>
        <FormColumn width={3}>
          <Select
            id="bats"
            label="Bats"
            placeholder="Bats"
            options={batsOptions}
            value={batsOptions.find((opt: ValueOpt<Bats>) => opt.value === metadata.bats)}
            onChange={(value: ValueOpt<Bats> | undefined) => onChange({ ...metadata, bats: value ? value.value : "" })}
            clearable={true}
          />
        </FormColumn>
        <FormColumn width={3}>
          <Select
            id="throws"
            label="Throws"
            placeholder="Throws"
            options={throwsOptions}
            value={throwsOptions.find((opt: ValueOpt<Throws>) => opt.value === metadata.throwsSide)}
            onChange={(value: ValueOpt<Throws> | undefined) =>
              onChange({ ...metadata, throwsSide: value ? value.value : "" })
            }
            clearable={true}
          />
        </FormColumn>
        <FormColumn width={3}>
          <Select
            id="height"
            label="Height"
            placeholder="Height"
            options={heightOptions}
            value={heightOptions.find((opt: ValueOpt<Heights>) => opt.value.key === metadata.height)}
            onChange={(value: ValueOpt<Heights> | undefined) =>
              onChange({ ...metadata, height: value ? value.value.key : "" })
            }
            clearable={true}
          />
        </FormColumn>
        <FormColumn width={3}>
          <Input
            id="weight"
            type="number"
            label="Weight"
            placeholder="Weight"
            value={metadata.weight}
            onChange={(value) => onChange({ ...metadata, weight: value })}
          />
        </FormColumn>
        <FormColumn width={3}>
          <DateInput
            id="birthDate"
            label="Birth Date"
            value={metadata.birthDate}
            onChange={(event) => onChange({ ...metadata, birthDate: event })}
            minDate={minDate}
            maxDate={new Date()}
          />
        </FormColumn>
        <FormColumn width={3}>
          <Input
            id="uniformNumber"
            type="number"
            label="Uniform Number"
            placeholder="Uniform Number"
            value={metadata.uni}
            onChange={(value) => onChange({ ...metadata, uni: value })}
          />
        </FormColumn>
        {isIntl && (
          <>
            <FormColumn width={3}>
              <Input
                id="homeAddress"
                label="Home Address"
                placeholder="Home Address"
                value={metadata.homeAddress}
                onChange={(value) => onChange({ ...metadata, homeAddress: value })}
              />
            </FormColumn>
            <FormColumn width={3}>
              <Input
                id="phoneNumber"
                label="Phone Number"
                placeholder="Phone Number"
                value={metadata.phoneNumber}
                onChange={(value) => onChange({ ...metadata, phoneNumber: value })}
              />
            </FormColumn>
          </>
        )}
      </div>

      <div className="mt-3">
        <Typography variant="h5">Video Details</Typography>
      </div>
      <div className="row">
        <FormColumn width={3}>
          <DateInput
            id="filmDate"
            label="Film Date"
            value={metadata.filmDate}
            onChange={(event) => onChange({ ...metadata, filmDate: event })}
            maxDate={new Date()}
          />
        </FormColumn>
        <FormColumn width={3}>
          <Input
            id="videoBy"
            label="Video By"
            placeholder="Video By"
            value={metadata.videoBy}
            onChange={(value) => onChange({ ...metadata, videoBy: value })}
          />
        </FormColumn>
        <FormColumn width={3}>
          <Select
            id="source"
            label="Source"
            placeholder="Source"
            disabled={true}
            options={orgOptions}
            value={orgOptions.find((opt: ValueOpt<Org>) => opt.value.name === metadata.source)}
            onChange={(value: ValueOpt<Org> | undefined) =>
              onChange({ ...metadata, source: !!value ? value.value.name : "" })
            }
            clearable={true}
          />
        </FormColumn>
        <FormColumn width={3}>
          <DateInput
            id="postedDate"
            label="Posted Date"
            value={metadata.postedDate}
            onChange={(event) => onChange({ ...metadata, postedDate: event })}
            maxDate={new Date()}
          />
        </FormColumn>
        <FormColumn width={3}>
          <DateInput
            id="uploadDate"
            label="Upload Date"
            disabled={true}
            value={metadata.uploadDate}
            onChange={(event) => onChange({ ...metadata, uploadDate: event })}
            maxDate={new Date()}
          />
        </FormColumn>
        <FormColumn width={3}>
          <Input
            id="clips1AssetId"
            label="Clips 1 Asset ID"
            placeholder="Clips 1 Asset ID"
            value={metadata.clips1AssetId}
            onChange={(value) => onChange({ ...metadata, clips1AssetId: value })}
          />
        </FormColumn>
        <FormColumn width={2}>
          <Textarea
            id="comments"
            label="Comments"
            placeholder="Comments"
            value={metadata.comments}
            onChange={(value) => onChange({ ...metadata, comments: value })}
          />
        </FormColumn>
        <FormColumn width={2}>
          <Select
            isMulti
            id="tags"
            label="Tags"
            placeholder="Tags"
            onChange={(value: ValueOpt<Tags>[]) =>
              onChange({ ...metadata, tags: value.map((t: ValueOpt<Tags>) => t.value.tag) })
            }
            options={tagOptions}
            value={
              !!metadata.tags?.length
                ? tagOptions.filter((opt: ValueOpt<Tags>) => metadata.tags.includes(opt.value.tag))
                : []
            }
            clearable={true}
          />
        </FormColumn>
      </div>
    </form>
  );
};

export default MetadataForm;
