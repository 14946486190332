import { Button, Typography } from "best-common-react";
import React from "react";
import { useSearch } from "../../../contexts/SearchContext";
import AdvancedSearchField from "./AdvancedSearchField";

const AdvancedSearchForm: React.FC = () => {
  const { search, fields, addNewField, removeField, updateField } = useSearch();
  return (
    <div>
      <Typography variant="h5">Search Filters</Typography>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          search();
        }}
      >
        {fields.map((f, index) => (
          <AdvancedSearchField
            key={f.key}
            field={f}
            onDelete={() => {
              removeField(index);
            }}
            onChange={(field) => {
              updateField(index, field);
            }}
          />
        ))}

        <div className="d-flex justify-content-end mb-2">
          <Button
            variant="link"
            type="submit"
            onClick={(event) => {
              event.preventDefault();
              addNewField();
            }}
          >
            Add New Filter
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AdvancedSearchForm;
