import { Tab, TabSwitcher } from "best-common-react";
import React, { useEffect } from "react";
import { useFolders } from "../../contexts/FoldersContext";
import PendingFolders from "./PendingFolders";
import UploadFolders from "./UploadFolders";

const FoldersBody = () => {
  const { setActiveTab } = useFolders();

  const Tabs: Tab[] = [
    { name: "Uploads", component: UploadFolders },
    { name: "Pending", component: PendingFolders },
  ];

  const setActiveTabByIndex = (index: number) => {
    setActiveTab(Tabs[index]);
    return true;
  };

  useEffect(() => {
    setActiveTab(Tabs[0]);
  }, []);

  return <TabSwitcher tabs={Tabs} setActiveTabIndex={setActiveTabByIndex} />;
};

export default FoldersBody;
