import { FormatterType, Icon, Tooltip } from "best-common-react";
import React, { useRef } from "react";
import { SearchResult } from "../../../types/Search";

const TagsFormatter: React.FC<FormatterType<SearchResult>> = ({ value }) => {
  const iconRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {value && value.length ? (
        <div ref={iconRef}>
          <Icon iconName="fa-tag" />
          <Tooltip ref={iconRef} placement="top" trigger="hover" delay={500} singleOpen={true}>
            {value}
          </Tooltip>
        </div>
      ) : null}
    </>
  );
};

export default TagsFormatter;
