import React, { useRef } from "react";
import styled from "styled-components";

const WIDTH = 150;
const HEIGHT = 100;

const Video = styled.video`
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
`;

const Canvas = styled.canvas`
  display: none;
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
`;

type VideoThumbnailProps = {
  src: string;
};

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({ src }) => {
  const canvas = useRef(null);
  const video = useRef(null);

  const draw = () => {
    if (canvas) {
      const ctx = !!canvas.current.getContext
        ? typeof canvas.current.getContext === "function"
          ? canvas.current.getContext("2d")
          : canvas.current.getContext
        : null;
      if (ctx) {
        ctx.drawImage(video, 0, 0, WIDTH * 2, HEIGHT * 2);
      }
    }
  };

  return (
    <div>
      <Video src={src} ref={video} onLoadedMetadata={draw} onLoadedData={draw} />
      <Canvas ref={canvas} />
    </div>
  );
};

export default VideoThumbnail;
