import React from "react";
import { BoxFileDTO } from "../../types/Video";
import VideoDetails from "./VideoDetails";
import Video from "./videoPlayer/Video";

type VideoBodyProps = {
  video: BoxFileDTO;
  setVideo: (value: BoxFileDTO) => void;
};

const VideoBody: React.FC<VideoBodyProps> = ({ video, setVideo }) => {
  const setVideoTags = (tags) => {
    setVideo({ ...video, tags: tags });
  };

  return (
    <div>
      {video.fileName && (
        <div>
          <Video url={video.boxSharedLink?.downloadURL} />
          <VideoDetails video={video} setVideoTags={setVideoTags} />
        </div>
      )}
    </div>
  );
};

export default VideoBody;
