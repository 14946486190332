import React from "react";
import { Navigate } from "react-router-dom";
import Login from "../components/login/Login";
import { RouteConstants } from "../constants/RouteConstants";
import { useAuth } from "../contexts/AuthContext";

const LoginContainer = () => {
  const { loggedIn, pathname } = useAuth();
  if (loggedIn) {
    const redirectUrl = !!pathname.length && pathname !== RouteConstants.LOGIN ? pathname : RouteConstants.BASE;
    return <Navigate to={redirectUrl} replace={true} />;
  } else {
    return <Login />;
  }
};

export default LoginContainer;
