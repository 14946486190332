import { Button, StickyFooterButtons, urlReplace } from "best-common-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../../constants/RouteConstants";

type MetadataEditFooterProps = {
  videoId: number | string;
  validToSave: boolean;
  save: () => Promise<any>;
};

const MetadataEditFooter: React.FC<MetadataEditFooterProps> = ({ videoId, validToSave, save }) => {
  const navigate = useNavigate();
  const backToVideo = () => {
    navigate(urlReplace(RouteConstants.VIDEO, { videoId }));
  };

  return (
    <StickyFooterButtons>
      <Button variant="default" onClick={backToVideo}>
        Back To Video
      </Button>
      <Button variant="primary" onClick={save} disabled={!validToSave}>
        Save
      </Button>
    </StickyFooterButtons>
  );
};

export default MetadataEditFooter;
