import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@material-ui/core";
import {
  AccessTime,
  Cake,
  DirectionsWalk,
  Edit,
  LocationOn,
  Person,
  SaveAlt,
  School,
  Share,
  Videocam,
} from "@material-ui/icons";
import { formatDate, Icon, urlReplace, useLoading } from "best-common-react";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RouteConstants } from "../../constants/RouteConstants";
import { useAuth } from "../../contexts/AuthContext";
import { downloadFile } from "../../httpClients/ClipsApi";
import { BoxFileDTO } from "../../types/Video";
import { formatName } from "../../utils/PersonUtils";
import EditTagsModal from "./EditTagsModal";
import VideoShareModal from "./VideoShareModal";

type MetadataItemsProps = {
  icon?: React.ReactElement;
  title?: string;
  value?: string;
};

const MetadataItem: React.FC<MetadataItemsProps> = ({ icon, title, value }) => {
  const Item = title ? <ListItemText primary={title} secondary={value} /> : <ListItemText primary={value} />;
  const Icon = icon ? <ListItemIcon>{icon}</ListItemIcon> : null;
  return (
    <div>
      {value && value.length && (
        <div>
          <ListItem>
            {Icon}
            {Item}
          </ListItem>
        </div>
      )}
    </div>
  );
};

type MetadataProps = MetadataItemsProps & {
  link?: string;
};

const Metadata: React.FC<MetadataProps> = ({ icon, title, value, link }) => (
  <div>
    {link ? (
      <Link to={link}>
        <MetadataItem icon={icon} title={title} value={value} />
      </Link>
    ) : (
      <MetadataItem icon={icon} title={title} value={value} />
    )}
  </div>
);

type VideoDetailsProps = {
  video: BoxFileDTO;
  setVideoTags: (value: string[]) => void;
};

const videoDateFormat = (value) => (!!value ? formatDate(DateTime.fromJSDate(new Date(value)).toUTC()) : "");

const VideoDetails: React.FC<VideoDetailsProps> = ({ video, setVideoTags }) => {
  const navigate = useNavigate();
  const videoId = video.fileId;
  const { setLoading } = useLoading();
  const { isAdmin, isBocAdmin, userInfo } = useAuth();
  const [canEditMetadata, setCanEditMetadata] = useState<boolean>(false);
  const [canEditTags, setCanEditTags] = useState<boolean>(false);
  const [videoShareOpen, setVideoShareOpen] = useState<boolean>(false);
  const [editTagsOpen, setEditTagsOpen] = useState<boolean>(false);

  const { metadata } = video;
  const tags = video.tags ? video.tags : [];
  const name: string = formatName(metadata.firstName, metadata.lastName);
  const filmDate: string = videoDateFormat(metadata.filmDate);
  const birthday: string = videoDateFormat(metadata.birthDate);
  const heightWeight: string = (
    (metadata.height ? metadata.height : "") +
    " " +
    (metadata.weight ? metadata.weight + "lbs" : "")
  ).trim();

  const download = async () => {
    try {
      setLoading(true);
      const result = await downloadFile(videoId);
      window.open(result, "_blank");
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const editMetadata = () => {
    navigate(urlReplace(RouteConstants.METADATA_EDIT, { fileId: videoId }));
  };

  useEffect(() => {
    setCanEditTags(userInfo?.org?.name && isAdmin && video.metadata && userInfo?.org?.name === video.metadata.source);
    setCanEditMetadata(
      userInfo?.org?.name && isAdmin && video.metadata && userInfo?.org?.name === video.metadata.source,
    );
  }, [userInfo, video, isAdmin]);

  return (
    <>
      <VideoShareModal videoId={videoId} open={videoShareOpen} close={() => setVideoShareOpen(false)} />
      <EditTagsModal
        existingTags={tags}
        videoId={videoId}
        open={editTagsOpen}
        close={() => setEditTagsOpen(false)}
        setVideoTags={setVideoTags}
      />
      <Card>
        <CardContent>
          <Grid container justify="space-between" className="mb-2">
            <Grid item>
              <Typography variant="h4">{name}</Typography>
            </Grid>
            <Grid item>
              <div>
                {(isBocAdmin || canEditMetadata) && (
                  <Button variant="contained" color="secondary" className="me-2" size="small" onClick={editMetadata}>
                    <Edit className="me-1" />
                    Edit Metadata
                  </Button>
                )}
                {(isBocAdmin || canEditTags) && (
                  <Button
                    variant="contained"
                    color="secondary"
                    className="me-2"
                    size="small"
                    onClick={() => setEditTagsOpen(true)}
                  >
                    <Edit className="me-1" />
                    Edit Tags
                  </Button>
                )}
                <Button variant="contained" className="me-2" size="small" onClick={() => setVideoShareOpen(true)}>
                  <Share className="me-1" />
                  Share
                </Button>
                <Button variant="contained" color="primary" size="small" onClick={download}>
                  <SaveAlt className="me-1" />
                  Download
                </Button>
              </div>
            </Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <List
                component="ul"
                subheader={<ListSubheader component="div">Personal Details</ListSubheader>}
                dense
                disablePadding
              >
                <Metadata icon={<Person />} value={formatName(metadata.firstName, metadata.lastName)} />
                <Metadata icon={<School />} value={metadata.school} />
                <Metadata icon={<Cake />} value={birthday} />
                <Metadata icon={<DirectionsWalk />} value={heightWeight} />
                <Metadata title="Position" value={metadata.position} />
                <Metadata title="Roster Name" value={metadata.rosterName} />
                <Metadata title="Middle Name" value={metadata.middleName} />
                <Metadata title="Extended Last Name" value={metadata.extendedLastName} />
                <Metadata title="Country" value={metadata.country} />
                <Metadata title="Bats" value={metadata.bats} />
                <Metadata title="Throws" value={metadata.throwsSide} />
                <Metadata title="Player Status" value={metadata.playerStatus} />
                <Metadata title="Player Id" value={metadata.playerId} />
                <Metadata title="EBIS Id" value={metadata.ebis} />
                <Metadata title="DPL Id" value={metadata.dplId} />
                <Metadata title="Eligibility" value={metadata.eligibility} />
                <Metadata title="Classification" value={metadata.classification} />
                <Metadata title="Trainer Name" value={metadata.trainerName} />
                <Metadata title="Uniform Number" value={metadata.uni} />
                <Metadata title="Org" value={metadata.org} />
                <Metadata title="Team" value={metadata.team} />
                <Metadata title="League" value={metadata.league} />
                <Metadata title="Email" value={metadata.email} />
                <Metadata title="Phone Number" value={metadata.phoneNumber} />
                <Metadata title="Home Address" value={metadata.homeAddress} />
              </List>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <List
                component="ul"
                subheader={<ListSubheader component="div">Video Details</ListSubheader>}
                dense
                disablePadding
              >
                <Metadata icon={<AccessTime />} value={filmDate} />
                <Metadata
                  icon={<LocationOn />}
                  value={metadata.city ? metadata.city + ", " + metadata.state : metadata.state ? metadata.state : ""}
                />
                <Metadata icon={<Videocam />} value={metadata.source} />
                <Metadata title="Film Name" value={metadata.fileName} />
                <Metadata title="Upload Date" value={videoDateFormat(metadata.uploadDate)} />
                <Metadata title="Posted Date" value={videoDateFormat(metadata.postedDate)} />
                <Metadata title="Video By" value={metadata.videoBy} />
                <Metadata title="Last Viewed" value={videoDateFormat(metadata.lastViewed)} />
                <Metadata title="CLIPs Org" value={metadata.clipsOrg} />
                <Metadata title="Comments" value={metadata.comments} />
              </List>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <List component="ul" subheader={<ListSubheader component="div">Tags</ListSubheader>} dense disablePadding>
                {tags.map((tag) => (
                  <Metadata
                    key={tag}
                    icon={<Icon iconName="fa-tag" styles={{ color: "rgba(0, 0, 0, 0.54)" }} />}
                    value={tag}
                    link={`/search?&tags=${tag}`}
                  />
                ))}
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default VideoDetails;
