import { DailyEmail } from "../types/Email";
import { MetadataFields } from "../types/Metadata";
import { UserDownloadsByOrg, UserViewsByOrg } from "../types/Reports";
import { Tags } from "../types/Tags";
import { MultiFileUploadDTO, UploadBatch } from "../types/Upload";
import { UserInfo } from "../types/User";
import { BoxFileDTO, BoxFileLiteDTO, BoxSearchResultsDTO, ClipsMetadata, ClipsMetadataDTO } from "../types/Video";
import ClipsClient from "./ClipsApiClient";

export const getUserInfo = (): Promise<UserInfo> => {
  const url = `/user/info`;
  return ClipsClient.get(url).then((response) => response.data.entity);
};

export const getVideo = (videoId: number | string): Promise<BoxFileDTO> => {
  const url = `/video/${videoId}`;
  return ClipsClient.get(url).then((response) => response.data.entity);
};

export const getVideoWithRelated = (videoId: number | string): Promise<BoxFileDTO> => {
  const url = `/video/${videoId}?related=true`;
  return ClipsClient.get(url).then((response) => response.data.entity);
};

export const getVideoPreview = (videoId: number | string): Promise<BoxFileDTO> => {
  const url = `/video/preview/${videoId}?related=false`;
  return ClipsClient.get(url).then((response) => response.data.entity);
};

export const getTags = (org: string): Promise<Tags[]> => {
  const url = `/tags/all/${org}`;
  return ClipsClient.get(url).then((response) => response.data.entity);
};

export const getMetadata = (fileId: number | string): Promise<ClipsMetadata> => {
  const url = `/metadata/${fileId}`;
  return ClipsClient.get(url).then((response) => response.data.entity);
};

export const getMetadataFields = (): Promise<MetadataFields> => {
  const url = `/metadata/template/fields`;
  return ClipsClient.get(url).then((response) => response.data.entity);
};

export const getMetadataTemplate = (): Promise<ClipsMetadataDTO> => {
  const url = `/metadata/template/file`;
  return ClipsClient.get(url).then((response) => response.data.entity);
};

export const search = (metadataSearch: ClipsMetadataDTO, pageNumber = 0): Promise<BoxSearchResultsDTO> => {
  const url = `/metadata/search?pageNumber=${pageNumber}`;
  return ClipsClient.post(url, metadataSearch).then((response) => response.data.entity);
};

export const singleFileUpload = (uploadData: FormData, straightToFinal = false): Promise<any> => {
  const url = `/uploads/single?toFinal=${straightToFinal}`;
  return ClipsClient.post(url, uploadData).then((response) => response.data.entity);
};

export const multiFileUpload = (uploadData: MultiFileUploadDTO): Promise<any> => {
  const url = `/uploads/multi`;
  return ClipsClient.post(url, uploadData).then((response) => response.data.entity);
};

export const uploadStatus = (): Promise<UploadBatch[]> => {
  const url = `/uploads/status`;
  return ClipsClient.get(url).then((response) => response.data.entity);
};

export const getFolderFiles = (folderId: string | number): Promise<BoxFileLiteDTO[]> => {
  const url = `/folder/files/${folderId}`;
  return ClipsClient.get(url).then((response) => response.data.entity);
};

export const moveFiles = (toFolderId: string | number, fileIds: number[] | string[]): Promise<any> => {
  const url = `/files/toMove/${toFolderId}`;
  return ClipsClient.post(url, fileIds).then((response) => response.data.entity);
};

export const shareFile = (fileId: string | number, emails: string[]): Promise<any> => {
  const url = `/video/share/${fileId}`;
  return ClipsClient.post(url, emails).then((response) => response.data.entity);
};

export const downloadFile = (fileId: string | number): Promise<string> => {
  const url = `/video/download/${fileId}`;
  return ClipsClient.get(url).then((response) => response.data.entity);
};

export const getViews = (org: string, startDate: string, endDate: string): Promise<UserViewsByOrg[]> => {
  const url = `/reports/views/${org}?startDate=${startDate}&endDate=${endDate}`;
  return ClipsClient.get(url).then((response) => response.data.entity);
};

export const getDownloads = (org: string, startDate: string, endDate: string): Promise<UserDownloadsByOrg[]> => {
  const url = `/reports/downloads/${org}?startDate=${startDate}&endDate=${endDate}`;
  return ClipsClient.get(url).then((response) => response.data.entity);
};

export const collaborateApi = (org: string, userId: number): Promise<any> => {
  const url = `/user/api/${org}/${userId}`;
  return ClipsClient.get(url).then((response) => response.data.entity);
};

export const addTag = (tag: string): Promise<Tags> => {
  const url = `/tags`;
  return ClipsClient.post(url, { tag: tag }).then((response) => response.data.entity);
};

export const updateMetadata = (fileId: string, metadata: ClipsMetadataDTO): Promise<boolean> => {
  const url = `/metadata/${fileId}`;
  return ClipsClient.put(url, metadata).then((response) => response.data.entity);
};

export const correctMetadata = (csv: ClipsMetadataDTO[]): Promise<any> => {
  const url = `/metadata/correct`;
  const body = {
    metadata: csv,
  };
  return ClipsClient.post(url, body).then((response) => response.data.entity);
};

export const toggleDailyEmail = (email: string): Promise<DailyEmail> => {
  const url = `/dailyEmail/subscribe`;
  return ClipsClient.put(url, { email }).then((response) => response.data.entity);
};

export const exportSearchResultsData = (metadata: any, pageNumber = 0) => {
  const url = `/metadata/search/export?pageNumber=${pageNumber}`;
  return ClipsClient.post(url, metadata, { responseType: "blob" });
};

export const updateTagsForVideo = (fileId: string, tags: Tags[]): Promise<any> => {
  const url = `/tags/update/${fileId}`;
  return ClipsClient.post(url, tags).then((response) => response.data.entity);
};
